import React from "react";
import { Component } from "react";

import PropTypes from "prop-types";
import { Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { Button, Container, Form } from "react-bootstrap";

import Autosuggest from "react-autosuggest";

import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStore,
  faUserClock,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

import DataTable from "react-data-table-component";
import { Alert } from "react-bootstrap";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const GoldMasterRestAPI = require("../../service/GoldMasterRestAPI.js").default;

export class InventoryCheckio extends Component {
  constructor() {
    super();
    this.state = {
      status: "N/A",
      suggestions: [],
      orderSuggestions: [],
      orderValue: "", // a temp_variable for saving the order's value

      selectedOrder: "",
      type: "",

      value: "",
      mapData: {},
      loading: false,
      totalRows: 0,
      perPage: 20,
    };

    this.CheckOut.bind(this);
    this.CheckIn.bind(this);
    this.getInventoryData();

    this.getAssociatedOrderID("TC06302-HVUIEL");
  }

  handlePageChange = (page) => {
    this.getPriceData(page);
  };
  handlePerRowsChange = (perPage, page) => {
    this.setState({ perPage: perPage }, () => {
      this.getProductData(page);
    }); // we don't call setState to avoid double call
  };

  onSuggestionsFetchRequested = ({ value }) => {
    GoldMasterRestAPI.getData({
      service: "inventory",
      perPage: 100,
      query_parameter: { _id: { $search: value } },
    }).then((apiResult) => {
      console.log("------ inv api call");
      console.log(apiResult);
      let result = [];
      apiResult.data.map((res) => {
        result.push({ ...res, label: res._id, value: res._id });
      });
      this.setState({
        suggestions: result,
      });
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
      value: "",
    });
  };

  onIDChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  getAssociatedOrderID = async (inventoryID) => {
    const { data: orders } = await GoldMasterRestAPI.getData({
      service: "orders",
      query_parameter: {
        "order.row.invId": { $search: inventoryID },
        // $or: [
        //   { 'status': 'auth_acquired' },
        //   { 'status': 'payment_direct' },
        // ]
      },
    });

    const { data: salesnote } = await GoldMasterRestAPI.getData({
      service: "salesnote",
      query_parameter: {
        "order.row.inventoryIdValue": { $search: inventoryID },
        // $or: [
        //   { 'status': 'auth_acquired' },
        //   { 'status': 'payment_direct' },
        // ]
      },
    });

    return { orders, salesnote };
  };

  updateOrderSuggestion = async (inventoryID) => {
    // console.log(`Suggestion on Order on ${inventoryID}`)
    const { orders, salesnote } = await this.getAssociatedOrderID(inventoryID);
    const mapped = [
      ...orders.map(({ _id }) => ({
        type: "orders",
        _id,
        label: _id,
        value: _id,
      })),
      ...salesnote.map(({ _id }) => ({
        type: "salesnote",
        _id,
        label: _id,
        value: _id,
      })),
    ];
    this.state.orderSuggestions = mapped;
  };

  getInventoryData = (page) => {
    const { perPage } = this.state;
    let show_page = page ? page : 1;

    let skip = (show_page - 1) * perPage;

    this.setState({ loading: true });

    GoldMasterRestAPI.getData({
      page: page,
      perPage: perPage,
      service: "inventory",
      query_parameter: { status: "checkout" },
    }).then((data) => {
      this.setState({
        mapData: data,
        totalRows: data.total,
        loading: false,
        perPage: perPage,
      });
    });

    //    console.info(data);
  };

  renderSuggestion = (suggestion) => <span>{suggestion.label}</span>;

  getSuggestionValue = (suggestion) => {
    //return suggestion.value;

    console.log("Suggestion");
    console.log(suggestion);
    this.setState(
      {
        selected: suggestion,
        //      unitPriceValue: suggestion.unitPrice,
        inventoryID_val: suggestion._id,
        productID: suggestion.productID,
        unitPriceValue: suggestion.rentalCost,
        desc: suggestion.ProductName,
        weight: suggestion.weight,
        status: suggestion.status,
        value: suggestion._id,

        orderValue: suggestion._id,
        metal: suggestion.Metal,
      },
      () => {
        this.updateOrderSuggestion(this.state.inventoryID_val);
        //this.updateSubtotalValue();
        //this.updateHook(this);
      }
    );
    //   this.unitPriceValue = suggestion.unitPrice;
    //   this.productID = suggestion.productID;
    //   this.inventoryID_val = suggestion._id;
    //   this.metal_val = suggestion.metal;

    //   this.updateHook(this);

    return suggestion.value;
  };

  CheckOut = () => {
    var id = this.state.inventoryID_val;
    GoldMasterRestAPI.patch({
      service: "inventory",
      data: {
        status: "checkout",

        orderID: this.state.selectedOrder,
        type: this.state.type,
      },
      id: id,
    }).then((apiResult) => {
      console.log("------ inv api call");
      console.log(apiResult);
      this.getInventoryData();
      this.setState({ status: "checkout" });
    });
  };
  CheckIn = () => {
    var id = this.state.inventoryID_val;
    GoldMasterRestAPI.patch({
      service: "inventory",

      data: {
        status: "ready",

        orderID: this.state.selectedOrder,
        type: this.state.type,
      },
      id: id,
    }).then((apiResult) => {
      console.log("------ inv api call");
      console.log(apiResult);
      this.getInventoryData();

      this.setState({ status: "ready" });
    });
  };

  Return = () => {
    confirmAlert({
      title: "Confirm Return?",
      message: "Press yes to confirm return",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            var id = this.state.inventoryID_val;
            GoldMasterRestAPI.patch({
              service: "inventory",

              data: {
                status: "return-to-vendor",
              },
              id: id,
            }).then((apiResult) => {
              console.log("------ inv api call");
              console.log(apiResult);
              this.getInventoryData();
              this.setState({ status: "return-to-vendor" });
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  Sold = () => {
    confirmAlert({
      title: "Confirm Sold?",
      message: "Press yes to confirm sold",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            var id = this.state.inventoryID_val;
            GoldMasterRestAPI.patch({
              service: "inventory",

              data: {
                status: "sold",
              },
              id: id,
            }).then((apiResult) => {
              console.log("------ inv api call");
              console.log(apiResult);
              this.getInventoryData();
              this.setState({ status: "sold" });
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  render() {
    const inputProps = {
      placeholder: "Inventory ID to update",
      value: this.state.value,
      onChange: this.onIDChange,
      className: "form-control",
    };
    return (
      <div>
        <h2>New Checkout</h2>
        <Container>
          <Row>
            <Col xs={3}>
              <InputGroup>
                <Autosuggest
                  suggestions={this.state.suggestions}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  getSuggestionValue={this.getSuggestionValue}
                  renderSuggestion={this.renderSuggestion}
                  //focusInputOnSuggestionClick={focusInputOnSuggestionClick}
                  inputProps={inputProps}
                  value={this.state.inventoryID_val}
                />

                <Alert
                  variant="primary"
                  show={this.state.inventoryID_val != null}
                >
                  {this.state.inventoryID_val}
                </Alert>
              </InputGroup>
            </Col>
            <Col xs={2}>
              <InputGroup>
                <Autosuggest
                  suggestions={this.state.orderSuggestions}
                  onSuggestionsFetchRequested={async ({ value }) =>
                    this.updateOrderSuggestion(value)
                  }
                  onSuggestionsClearRequested={() => {
                    // console.log("clear requested")
                    // will only clear when suggestion is selected
                  }}
                  alwaysRenderSuggestions={true}
                  getSuggestionValue={(suggestion) => {
                    // console.log(`get Suggestion Value ${suggestion._id}`)
                    this.setState({
                      selectedOrder: suggestion._id,
                      type: suggestion.type,

                      orderSuggestions: [],
                      orderValue: "",
                    });
                  }}
                  renderSuggestion={this.renderSuggestion}
                  //focusInputOnSuggestionClick={ focusInputOnSuggestionClick }
                  inputProps={{
                    placeholder: "Order ID",
                    value: this.state.orderValue || "",
                    onChange: (_, { newValue }) =>
                      this.setState({ orderValue: newValue }),
                    className: "form-control",
                    readOnly: !this.state.inventoryID_val,
                  }}
                  value={this.state.orderValue}
                />

                <Alert variant="primary" show={this.state.selectedOrder}>
                  {this.state.selectedOrder}
                </Alert>
              </InputGroup>
            </Col>
            <Col xs={3}>
              <InputGroup.Text>
                <InputGroup.Prepend>
                  <InputGroup.Text>Status</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl placeholder="" value={this.state.status} />
              </InputGroup.Text>
            </Col>
            <Col xs={1}>
              <Button
                variant="warning"
                onClick={this.CheckOut}
                disabled={
                  this.state.status != null && this.state.status != "ready"
                }
              >
                Check Out
              </Button>
            </Col>
            <Col xs={1}>
              <Button
                variant="primary"
                onClick={this.CheckIn}
                disabled={
                  this.state.status != null && this.state.status != "checkout"
                }
              >
                Check In
              </Button>
            </Col>
            <Col xs={1}>
              <Button
                variant="info"
                onClick={this.Return}
                disabled={
                  this.state.status != null && this.state.status != "ready"
                }
              >
                Return to Vendor
              </Button>
            </Col>
            <Col xs={1}>
              <Button
                variant="warning"
                onClick={this.Sold}
                disabled={
                  this.state.status != null && this.state.status != "ready"
                }
              >
                Sold
              </Button>
            </Col>
          </Row>
        </Container>

        <h2>Checked Out Inventory</h2>
        <DataTable
          columns={columns}
          data={this.state.mapData.data}
          pagination
          striped
          highlightOnHover
          paginationTotalRows={this.state.mapData.total}
          paginationServer
          onChangeRowsPerPage={this.handlePerRowsChange}
          onChangePage={this.handlePageChange}
        />
      </div>
    );
  }
}

InventoryCheckio.propTypes = {};

InventoryCheckio.defaultProps = {};

export default InventoryCheckio;

const columns = [
  {
    name: "Product ID",
    selector: "productID",
    sortable: true,
    right: true,
  },

  {
    name: "Inventory ID",
    selector: "_id",
    sortable: true,
    right: true,
  },

  {
    name: "Status",
    selector: "status",
    sortable: true,
    right: true,
    format: (row, index) => {
      let icon = faUserClock;
      let color = "";

      switch (row.status) {
        case "checkout":
          color = "";
          icon = faUserClock;
          break;
        case "ready":
          icon = faStore;
          color = "green";
          break;
        case "sold":
          icon = faStore;
          color = "yellow";
          break;
        case "return-to-vendor":
          icon = faStore;
          color = "yellow";
          break;

        default:
          icon = faQuestionCircle;
          color = "orange";
      }

      return (
        <div>
          <FontAwesomeIcon icon={icon} color={color} />
          {row.status}
        </div>
      );
    },
  },
  { name: "Owner", selector: "owner", sortable: true, right: true },
  {
    name: "Weight",
    selector: "weight",
    sortable: true,
    right: true,
  },
  {
    name: "Create",
    selector: "createdAt",
    sortable: true,
    format: (row, index) => {
      return moment(row.createdAt).tz("Asia/Hong_Kong").format("lll");
    },
  },
  {
    name: "Create By",
    selector: "createdBy",
    sortable: true,
    format: (row, index) => {
      return row.createdBy?.email || "N/A";
    },
  },
  {
    name: "Update",
    selector: "updateAt",
    sortable: true,
    format: (row, index) => {
      return moment(row.updateAt).tz("Asia/Hong_Kong").format("lll");
    },
  },
  {
    name: "Update By",
    selector: "updateBy",
    sortable: true,
    format: (row, index) => {
      return row.updateBy?.email || "N/A";
    },
  },
];
