import React, { Component } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "react-data-table-component";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStore,
  faUserClock,
  faQuestionCircle,
  faPenSquare,
} from "@fortawesome/free-solid-svg-icons";

import ProductInv from "./ProductInv";
import { confirmAlert } from "react-confirm-alert";

import { Link } from "react-router-dom";

const GoldMasterRestAPI = require("../../service/GoldMasterRestAPI.js").default;

//https://ellismin.com/2020/05/next-pagination/

class ProductList extends Component {
  //var mapData;
  //var setMapData;
  state = {
    mapData: {},
    loading: false,
    totalRows: 0,
    perPage: 20,
    dummy: 0,
    currentPage: 1,
    sortDirection: 1, // or -1 (1 is ascending, -1 is descending)
    sortSelector: null,
  };

  handleOnSort = (column, sortDirection) => {
    // https://github.com/jbetancur/react-data-table-component#sorting
    const { selector } = column;

    this.setState(
      {
        sortDirection: sortDirection === "asc" ? 1 : -1, // assume there are only asc and desc
        sortSelector: selector,
      },
      () => this.getProductData()
    );
  };

  handlePageChange = (page) => {
    this.setState(
      {
        currentPage: page,
      },
      () => this.getProductData(page)
    );
  };
  handlePerRowsChange = (perPage, page) => {
    this.setState(
      {
        perPage: perPage,
        currentPage: page,
      },
      () => {
        this.getProductData(page);
      }
    ); // we don't call setState to avoid double call
  };

  getProductData = (page = this.state.currentPage) => {
    const { perPage, sortSelector, sortDirection } = this.state;
    let show_page = page ? page : 1;

    let skip = (show_page - 1) * perPage;

    this.setState({ loading: true });

    GoldMasterRestAPI.getData({
      page: page,
      perPage: perPage,
      service: "product",
      // https://github.com/gaearon/feathers-docs/blob/master/databases/pagination.md#sort
      sort: sortSelector ? { [sortSelector]: sortDirection } : null,
    }).then((data) => {
      console.log(data);
      this.setState({
        mapData: data,
        totalRows: data.total,
        loading: false,
        perPage: perPage,
      });
    });

    //    console.info(data);
  };

  componentDidMount() {
    this.getProductData();
  }

  columns = [
    {
      name: "Product ID",
      selector: "ProductID",
      sortable: true,
      right: true,
    },

    {
      name: "Product Name",
      selector: "ProductName",
      sortable: true,
      right: true,
    },
    {
      name: "Metal",
      selector: "Metal",
      sortable: true,
      right: true,
    },
    {
      name: "Description",
      selector: "desc",
      sortable: true,
      right: true,
      wrap: true,
    },
    {
      name: "Vendor",
      selector: "Vendor",
      sortable: true,
      right: true,
    },

    {
      name: "Status",
      selector: "status",
      sortable: true,
      right: true,
      format: (row, index) => {
        let icon = faUserClock;
        let color = "";

        switch (row.status) {
          case "rentout":
            color = "";
            icon = faUserClock;
            break;
          case "ready":
            icon = faStore;
            color = "green";
            break;
          default:
            icon = faQuestionCircle;
            color = "orange";
        }

        return <FontAwesomeIcon icon={icon} color={color} />;
      },
    },

    {
      name: "Weight(g)",
      selector: "weight",
      sortable: true,
      right: true,
    },
    {
      name: "Weight(tael)",
      selector: "weight",
      sortable: true,
      right: true,
      format: (row, index) => {
        return (row.weight / 37.429).toFixed(3);
      },
    },
    {
      name: "rentalCost",
      selector: "rentalCost",
      sortable: true,
      right: true,
    },

    {
      name: "Create",
      selector: "createdAt",
      sortable: true,
      format: (row, index) => {
        return (
          (row.createdAt &&
            moment(row.createdAt).tz("Asia/Hong_Kong").format("lll")) ||
          "N/A"
        );
      },
    },
    {
      name: "Create By",
      selector: "createdBy",
      sortable: true,
      format: (row, index) => {
        return row.createdBy?.email || "N/A";
      },
    },
    {
      name: "Update",
      selector: "updateAt",
      sortable: true,
      format: (row, index) => {
        return (
          (row.updateAt &&
            moment(row.updateAt).tz("Asia/Hong_Kong").format("lll")) ||
          "N/A"
        );
      },
    },
    {
      name: "Update By",
      selector: "updateBy",
      sortable: true,
      format: (row, index) => {
        return row.updateBy?.email || "N/A";
      },
    },

    {
      name: "Orderd",
      selector: "status",
      sortable: true,
      format: (row, index) => {
        return (
          <div>
            {row.inventoryRented ? row.inventoryRented : 0}/
            {row.inventoryCount ? row.inventoryCount : 0}/
            {row.ordered ? row.ordered : 0}
          </div>
        );
      },
    },
    {
      name: "Action",
      selector: "status",
      format: (row, index) => {
        return <div>
            <Link to={`/ProductEdit/${row._id}`}>
              <FontAwesomeIcon
                size="lg"
                icon={faPenSquare}
                style={{ marginLeft: "5px" }}
                color="red"
              />
            </Link>

        </div>;
      },
    },
  ];

  /*
            <FontAwesomeIcon
              size="lg"
              icon={faMinusSquare}
              color="red"
              onClick={this.deleteProduct.bind(this, row)}
              style={{ display: "none" }}
            />
            <Link to={`/ProductEdit/${row._id}`}>
              <FontAwesomeIcon
                size="lg"
                icon={faPenSquare}
                style={{ marginLeft: "5px", display: "none" }}
                color="red"
                style={{ display: "none" }}
              />
            </Link>

*/

  deleteProduct = (row, event) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: `Are you sure to delete Product ${row._id}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.info(row._id);
            GoldMasterRestAPI.remove({
              id: row._id,
              service: "product",
            }).then((result) => {
              this.getProductData();
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    return (
      <>
        <h1>
          Products: {this.state.mapData.total} Entries
          {/* {JSON.stringify(data) */}
        </h1>
        {
          //<button onClick={this.getPriceData}>Update</button>
        }
        <DataTable
          columns={this.columns}
          data={this.state.mapData.data}
          pagination
          striped
          highlightOnHover
          paginationTotalRows={this.state.mapData.total}
          paginationServer
          onChangeRowsPerPage={this.handlePerRowsChange}
          onChangePage={this.handlePageChange}
          expandableRows
          expandableRowsComponent={<ProductInv />}
          paginationPerPage={this.state.perPage}
          paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
          dummy={this.state.dummy}
          sortServer
          onSort={this.handleOnSort}
        />
      </>
    );
  }
}

export default ProductList;
