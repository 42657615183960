import { faMinusSquare, faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment-timezone";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

const GoldMasterRestAPI = require("../../service/GoldMasterRestAPI.js").default;

//https://ellismin.com/2020/05/next-pagination/

class OrderNoteList extends Component {
  //var mapData;
  //var setMapData;
  state = {
    mapData: {},
    loading: false,
    totalRows: 0,
    perPage: 20,
    currentPage: 1,
    sortDirection: 1, // or -1 (1 is ascending, -1 is descending)
    sortSelector: null,
  };

  deleteOrderNote = (row) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: `Are you sure to delete Order Note ${row._id}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            GoldMasterRestAPI.remove({
              id: row._id,
              service: "rental-invoice",
            }).then((result) => {
              this.getPriceData();
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  handleOnSort = (column, sortDirection) => {
    // https://github.com/jbetancur/react-data-table-component#sorting
    const { selector } = column;

    this.setState(
      {
        sortDirection: sortDirection === "asc" ? 1 : -1, // assume there are only asc and desc
        sortSelector: selector,
      },
      () => this.getPriceData()
    );
  };

  handlePageChange = (page) => {
    this.setState(
      {
        currentPage: page,
      },
      () => this.getPriceData(page)
    );
  };
  handlePerRowsChange = (perPage, page) => {
    this.setState(
      {
        perPage: perPage,
        currentPage: page,
      },
      () => {
        // we don't call setState to avoid double call
        this.getPriceData(page);
      }
    );
  };

  getPriceData = (page = this.state.currentPage) => {
    const { perPage, sortSelector, sortDirection } = this.state;
    let show_page = page ? page : 1;

    let skip = (show_page - 1) * perPage;

    this.setState({ loading: true });

    GoldMasterRestAPI.getData({
      page: page,
      perPage: perPage,
      service: "rental-invoice",
      // https://github.com/gaearon/feathers-docs/blob/master/databases/pagination.md#sort
      sort: sortSelector ? { [sortSelector]: sortDirection } : null,
    }).then((data) => {
      console.log(data[0]);
      this.setState({
        mapData: data,
        totalRows: data.total,
        loading: false,
        perPage: perPage,
      });
      console.info(data);
    });
  };

  componentDidMount() {
    this.getPriceData();
  }

  columns = [
    {
      name: "Order Note ID",
      selector: "_id",
      sortable: true,
      right: true,
      wrap: true,
    },

    {
      name: "Invoice Num",
      selector: "order.field_invoiceno",
      sortable: true,
      right: true,
    },

    // {
    //   name: "Status",
    //   selector: "status",
    //   sortable: true,
    //   right: true,
    //   format: (row, index) => {
    //     let icon = faUserClock;
    //     let color = "";

    //     switch (row.status) {
    //       case "rentout":
    //         color = "";
    //         icon = faUserClock;
    //         break;
    //       case "ready":
    //         icon = faStore;
    //         color = "green";
    //         break;
    //       default:
    //         icon = faQuestionCircle;
    //         color = "orange";
    //     }

    //     return <FontAwesomeIcon icon={icon} color={color} />;
    //   },
    // },

    {
      name: "Total",
      selector: "order.total",
      sortable: true,
      right: true,
    },
    {
      name: "Weight",
      selector: "order.totalWeight",
      sortable: true,
      right: true,
    },
    {
      name: "Client Name",
      selector: "order.field_clientno",
      sortable: true,
      right: true,
    },

    {
      name: "Client Num",
      selector: "order.field_client",

      sortable: true,
      right: true,
    },

    {
      name: "Mobile",
      selector: "order.field_mobile",
      sortable: true,
      right: true,
    },

    {
      name: "Items",
      selector: "order.rows",
      sortable: true,
      right: true,
      format: (row, index) => {
        return row.order.row.reduce((acc, elm) => {
          return acc + " " + elm.productIdValue;
        }, "");
      },
    },
    {
      name: "Rent",
      selector: "order.rentfrom",
      sortable: true,
      format: (row, index) => {
        return (
          moment(row.order.field_rentfrom)
            .tz("Asia/Hong_Kong")
            .format("DD-MM") +
          " / " +
          moment(row.order.field_rentto).tz("Asia/Hong_Kong").format("DD-MM-YY")
        );
      },
    },

    {
      name: "Create",
      selector: "createdAt",
      sortable: true,
      format: (row, index) => {
        return moment(row.createdAt).tz("Asia/Hong_Kong").format("lll");
      },
    },
    {
      name: "Update",
      selector: "updateAt",
      sortable: true,
      format: (row, index) => {
        return moment(row.updateAt).tz("Asia/Hong_Kong").format("lll");
      },
    },
    {
      name: "PDF Link",
      selector: "pdfLink",
      // sortable: true,
      format: (row, index) => {
        return (
          (row.pdfLink && (
            <a href={row.pdfLink} target="_blank">
              PDF
            </a>
          )) || <div></div>
        );
      },
    },
    {
      name: "Action",
      selector: "status",
      format: (row, index) => {
        return (
          <div>
            <Link to={`/OrderNoteEdit/${row._id}`}>
              <FontAwesomeIcon
                size="lg"
                icon={faPenSquare}
                style={{ marginLeft: "5px" }}
                color="red"
              />
              
            </Link>
          </div>
        );
      },
    },
  ];
  /*
            <FontAwesomeIcon
              size='lg'
              icon={ faMinusSquare }
              color='red'
              onClick={ () => this.deleteOrderNote(row) }
            />
            <Link to={ `/OrderNoteEdit/${row._id}` }>
              <FontAwesomeIcon
                size='lg'
                icon={ faPenSquare }
                style={ { marginLeft: '5px' } }
                color='red'
              />
            </Link>

*/

  render() {
    return (
      <>
        <h1>
          Order Note List of {this.state.mapData.total} Entries{" "}
          {/* {JSON.stringify(data) */}
        </h1>
        {
          //<button onClick={this.getPriceData}>Update</button>
        }
        <DataTable
          columns={this.columns}
          data={this.state.mapData.data}
          pagination
          striped
          highlightOnHover
          paginationTotalRows={this.state.mapData.total}
          paginationServer
          onChangeRowsPerPage={this.handlePerRowsChange}
          onChangePage={this.handlePageChange}
          paginationPerPage={this.state.perPage}
          paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
          sortServer
          onSort={this.handleOnSort}
        />
      </>
    );
  }
}

export default OrderNoteList;
