import React, { Component } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Header from "./layouts/Header";
import { Provider, connect } from "react-redux";
import configureStore from "./GoldStore";

const store = configureStore();
const Container = connect()(Header);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Provider store={store}>
        <div className="App">
          {/* <Header /> */}
          <Container />
        </div>
      </Provider>
    );
  }
}

export default App;
