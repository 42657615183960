import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faCoffee,
  faCircle,
  faSync,
  faCheckCircle,
  faExclamationTriangle,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import Alert from "react-bootstrap/Alert";

import jquery from "jquery";
import $ from "jquery";
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";

const GoldMasterRestAPI = require("../service/GoldMasterRestAPI.js").default;

//https://fontawesome.com/icons/sync?style=solid
//https://react-bootstrap.github.io/components/input-group/

class InventoryInputRow extends Component {
  state = { icon: "" };

  constructor({ props, icon, submit, mykey }) {
    super(props);
    this.state.icon = icon;
    this.state.owner = "Kallisti";
    this.state.weightTael = 0;
    this.state.weight = 0;
    this.className = "";
    this.submitCall = submit;
    this.key = mykey;

    this.inventoryID = React.createRef();
    this.weight = React.createRef();
    this.ownerRef = React.createRef();
    this.labourFee = React.createRef();
    this.weighttael = React.createRef();
    //this.setState({ icon: icon });
  }

  formatWeight = (amount) => {
    return Number.parseFloat(amount).toFixed(3);
  };
  getKey = () => {
    return this.key;
  };
  setIcon = (icon) => {
    this.setState({ icon: icon });
  };

  setLastError = (error) => {
    this.lastError = error;
  };
  setStatus = (status) => {
    let icon = faCircle;
    let className = "";
    let color = "";
    let error = this.lastError ? this.lastError : {};
    switch (status) {
      case "init":
        icon = faCircle;

        break;
      case "ing":
        icon = faSync;
        className = "fa-spin";
        break;
      case "done":
        icon = faCheckCircle;
        color = "green";
        break;

      case "error":
        icon = faExclamationTriangle;
        color = "red";
        break;
      default:
        icon = faCircle;
        break;
    }
    this.setState({
      icon: icon,
      className: className,
      color: color,
      lastError: error,
      valid: false,
    });
  };

  handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      this.setState({ valid: true });
      e.preventDefault();
      e.stopPropagation();
    } else {
      this.setState({ valid: true });
      this.submitCall(this);
      e.preventDefault();
      e.stopPropagation();
    }
  };

  submit = () => {
    this.submitCall(this);
  };

  handleOwnerChange = (e) => {
    this.setState({ owner: e.target.value });

    e.preventDefault();
  };

  invRandGenerate = (e) => {
    let v = e.target.value;

    let c = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    var charactersLength = c.length;
    let r = "";

    for (var i = 0; i < 6; i++) {
      r += c.charAt(Math.floor(Math.random() * charactersLength));
    }

    let targetID = v + "-" + r;

    GoldMasterRestAPI.getDataByID({ id: targetID, service: "inventory" })
      .then((data) => {
        this.invRandGenerate(e);
      })
      .catch((data) => {
        if (data.name == "NotFound") {
          this.inventoryID.current.value = v + "-" + r;
        }
      });
  };
  render = () => (
    <Form noValidate validated={this.state.valid} onSubmit={this.handleSubmit}>
      <Row>
        <Col xs={1}>
          <InputGroup>
            <FormControl
              placeholder="PID"
              aria-label="PID"
              aria-describedby="PID"
              ref={this.productID}
              required
              onChange={this.invRandGenerate}
            />
          </InputGroup>
        </Col>

        <Col xs={3}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="InventoryID">InventoryID</InputGroup.Text>
            </InputGroup.Prepend>

            <FormControl
              placeholder="Inventory ID"
              aria-label="Inventory ID"
              aria-describedby="InventoryID"
              ref={this.inventoryID}
              required
            />
          </InputGroup>
        </Col>
        <Col xs={2}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="OwnerID">Owner</InputGroup.Text>
            </InputGroup.Prepend>

            <FormControl
              placeholder="Owner"
              aria-label="Owner ID"
              aria-describedby="OwnerID"
              ref={this.ownerRef}
              value={this.state.owner}
              onChange={this.handleOwnerChange}
            />
          </InputGroup>
        </Col>
        <Col xs={2}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="LabourFee">Labour Fee</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="0"
              aria-label="Labour Fee"
              aria-describedby="LabourFee"
              ref={this.labourFee}
              defaultValue={0}
              required
            />
          </InputGroup>
        </Col>
        {/* <Col xs={1}>
          <InputGroup>
            <FormControl
              placeholder='Weight'
              aria-label='Weight'
              aria-describedby='basic-addon2'
              ref={this.weight}
              required
            />
            <InputGroup.Append>
              <InputGroup.Text id='basic-addon2'>Gram</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col> */}
        <Col xs={2}>
          <InputGroup>
            <FormControl
              placeholder="Reference Weight"
              aria-label="Reference Weight"
              aria-describedby="basic-addon2"
              required
              ref={this.weight}
              value={this.state.weight}
              onChange={(e) => {
                let weighttael = this.formatWeight(e.target.value / 37.429);
                this.setState({
                  weight: e.target.value,
                  weighttael: weighttael,
                });
              }}
            />
            <InputGroup.Append>
              <InputGroup.Text id="basic-addon2">Gram</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
          <InputGroup>
            <FormControl
              placeholder="Weight (Tael)"
              aria-label="weighttael"
              aria-describedby="weighttael"
              ref={this.weighttael}
              value={this.state.weighttael}
              onChange={(e) => {
                let weight = this.formatWeight(e.target.value * 37.429);
                this.setState({
                  weight: weight,
                  weighttael: e.target.value,
                });

                this.setState({ weighttael: e.target.value });
              }}
            />

            <InputGroup.Append>
              <InputGroup.Text id="basic-addon2">Tael</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
        <Col xs={2}>
          <button type="submit">Submit</button>
          <FontAwesomeIcon
            icon={this.state.icon}
            className={this.state.className}
            onClick={this.submit}
            color={this.state.color}
          />
        </Col>
        {this.state.lastError &&
          this.state.lastError !== {} &&
          this.state.lastError.message && (
            <Col xs={12}>
              <Alert variant="danger">{this.state.lastError.message}</Alert>
            </Col>
          )}
        <Col xs={6}>
          <Link to="/Inventory">
            <button style={{ float: "left", marginTop: "20px" }} type="button">
              Back
            </button>
          </Link>
        </Col>
      </Row>
    </Form>
  );
}

export class InventoryInput extends Component {
  submitToAPI = (payload) => {
    let { target, inventoryID, weight, owner, labourFee } = payload;

    //inventoryID = "https://www.k-gold.shop/p/nl009-0001";
    let productID = "";

    //try to extract the code and put it into productID
    let regPattern = new RegExp(
      ".*\\/p\\/([a-z][a-z][_\\d]+)-(\\S+?)\\/*$",
      "i"
    );
    let matchReg = regPattern.exec(inventoryID);

    if (matchReg) {
      productID = matchReg[1];
      inventoryID = matchReg[1] + "-" + matchReg[2];
    } else {
      let matchReg2 = new RegExp("([a-z][a-z][_\\d]+)-(\\S+?)\\/*$", "i").exec(
        inventoryID
      );
      if (matchReg2) {
        productID = matchReg2[1];
        inventoryID = matchReg2[1] + "-" + matchReg2[2];
      }
    }
    //console.info(matchReg);

    GoldMasterRestAPI.create({
      service: "inventory",
      data: {
        _id: inventoryID,
        weight: weight,
        labourFee: labourFee,
        status: "ready",
        productID: productID,
        owner: owner,
      },
    })
      .then((data) => {
        target.setStatus("done");
      })
      .catch((e) => {
        target.setLastError(e);
        target.setStatus("error");
      });
    //target.setStatus("error");
  };

  submitInput = (targetDom) => {
    this.cellIdCount++;

    //console.info(hi);
    //hi.setIcon(faCoffee);

    this.cells.forEach((elm, index) => {
      if (elm.key === targetDom.key) {
        this.cells[index].icon = faCoffee;
      }
    });

    targetDom.setStatus("ing");

    this.submitToAPI({
      target: targetDom,
      inventoryID: targetDom.inventoryID.current.value,
      weight: targetDom.weight.current.value,
      owner: targetDom.ownerRef.current.value,
      labourFee: targetDom.labourFee.current.value,
    });

    console.log({
      target: targetDom,
      inventoryID: targetDom.inventoryID.current.value,
      weight: targetDom.weight.current.value,
      owner: targetDom.ownerRef.current.value,
      labourFee: targetDom.labourFee.current.value,
    });

    //create new cell
    this.cells.push({ key: this.cellIdCount, icon: faCircle });
    //update view
    this.updateState();
  };

  cellIdCount = 0;
  cells = [];

  constructor() {
    super();
    this.cells = [{ key: 0, icon: faCircle }];
    this.state = { cells: this.cells };
  }

  updateState = () => {
    this.setState({ cells: this.cells });
  };

  render() {
    let cellsTable =
      /*
        (
      <InventoryInputRow icon={faSync} submit={this.submitInput} />
        );
        */
      this.state.cells.map((cell) => {
        return (
          <InventoryInputRow
            icon={cell.icon}
            key={cell.key}
            mykey={cell.key}
            submit={this.submitInput}
          />
        );
      });

    return (
      <Form>
        <Container>{cellsTable}</Container>
      </Form>
    );
  }
}

export default InventoryInput;
