import React, { Component } from "react";
import { Row, Col, InputGroup, FormControl } from "react-bootstrap";
import {
  faCircle,
  faSync,
  faCheckCircle,
  faExclamationTriangle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Alert from "react-bootstrap/Alert";
import Autosuggest from "react-autosuggest";
import isMobile from "ismobilejs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from "react-number-format";

const GoldMasterRestAPI = require("../../service/GoldMasterRestAPI.js").default;

//https://react-select.com/async

const getOptions = (input) => {
  let data = [];

  return data;
};

const focusInputOnSuggestionClick = !isMobile.any;

const renderSuggestion = (suggestion) => <div>{suggestion.label}</div>;
export class RentalNoteInputRow extends Component {
  state = { icon: "", suggestions: [] };

  constructor({
    props,
    icon,
    submit,
    mykey,
    goldPrice,
    updated,
    checkIsDuplcated,
    inputMode,
    defaultValues = {},
  }) {
    super(props);
    this.state.icon = defaultValues?.icon || icon;
    this.state.value = defaultValues?.productId || "";
    this.state.suggestions = [];
    this.state.weightValue = defaultValues?.weight || 0.0;
    this.state.weightTael=defaultValues?.weight *  37.429|| 0.0;
    this.state.desc = defaultValues?.desc || "";
    this.state.productID = defaultValues?.productId || "";
    this.state.metal = defaultValues?.metal || "AU999.9";
    this.inventoryID_val = defaultValues.invId;

    this.className = "";
    this.submitCall = submit;
    this.key = mykey;

    this.updateHook = updated;

    this.state.goldprice = goldPrice;

    this.state.subtotal =
      (this.state.weightValue ? this.state.weightValue : 0) *
      this.state.goldprice;

    this.inventoryID = React.createRef();
    this.weight = React.createRef();
    this.subtotal = React.createRef();
    this.desc = React.createRef();
    this.metal = React.createRef();

    this.inputMode = inputMode;

    this.checkIsDuplcated = checkIsDuplcated;
    //this.setState({ icon: icon });
  }

  componentWillReceiveProps({ goldPrice }) {
    this.setState({ goldprice: goldPrice });
  }

  getSubtotal = () => {
    return (this.weightValue ? this.weightValue : 0) * this.state.goldprice;
  };

  getSuggestionValue = (suggestion) => {
    console.log("Suggestion RN");
    console.log(suggestion);

    this.setState({
      selected: suggestion,
      weightValue: suggestion.weight,
      inventoryID_val: suggestion._id,
      productID: suggestion.productID,
    });

    //desc: suggestion.ProductName,

    GoldMasterRestAPI.getData({
      service: "product",
      perPage: 100,
      query_parameter: { _id: suggestion.productID },
    }).then((result) => {
      //console.log(apiResult);

      console.info(result?.data);
      console.info(result?.data[0]?.ProductName);
      this.setState(
        {
          desc: result?.data[0]?.ProductName,
          metal: result?.data[0]?.Metal,
        },
        () => {
          this.updateHook(this);
        }
      );
    });

    this.weightValue = suggestion.weight;
    this.productID = suggestion.productID;
    this.inventoryID_val = suggestion._id;
    //this.metal_val = suggestion.metal;

    this.weightTaleValue = GoldMasterRestAPI.formatWeight(
      suggestion.weight / 37.429
    );
    this.updateHook(this);

    return suggestion.value;
  };
  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    GoldMasterRestAPI.getData({
      service: "inventory",
      perPage: 100,
      query_parameter: { _id: { $search: value }, status: "ready" },
    }).then((apiResult) => {
      //console.log(apiResult);
      let result = [];

      console.log("Check Duplicate");
      apiResult.data.map((res) => {
        console.log(res);
        if (!this.checkIsDuplcated(res._id)) {
          result.push({ ...res, label: res._id, value: res._id });
        }
      });

      // { label: "1", value: "1", weight: 1.0 },
      // { label: "1cccccc", value: "1112", weight: 2.0 },
      // { label: "bbbbbbbb1", value: "bbb", weight: 3.0 },

      this.setState({
        suggestions: result,
      });
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  clearSelection = () => {
    this.setState({ selected: null, value: "", weightValue: "" }, () => {
      this.updateHook(this);
    });
    this.weightValue = 0;
    this.value = "";

    this.inventoryID_val = null;
  };

  getKey = () => {
    return this.key;
  };

  getData = () => {
    //console.log("get result froim child");

    //state value are not updated yet
    let result = {};
    result.weight = this.state.weightValue;
    result.subtotal = this.subtotal.current.value;
    result.desc = this.desc.current.value;
    result.productId =  this.state.productID;
    result.metal = this.state.metal;
    result.invId = this.inventoryID_val;
    result.weightTael = this.weightTaleValue = GoldMasterRestAPI.formatWeight(
      this.weightValue / 37.429
    );

    return result;
  };

  setIcon = (icon) => {
    this.setState({ icon: icon });
  };

  setLastError = (error) => {
    this.lastError = error;
  };
  setStatus = (status) => {
    let icon = faCircle;
    let className = "";
    let color = "";
    let error = this.lastError ? this.lastError : {};
    switch (status) {
      case "init":
        icon = faCircle;
        break;
      case "ing":
        icon = faSync;
        className = "fa-spin";
        break;
      case "done":
        icon = faCheckCircle;
        color = "green";
        break;

      case "error":
        icon = faExclamationTriangle;
        color = "red";
        break;
      default:
        icon = faCircle;
        break;
    }
    this.setState({
      icon: icon,
      className: className,
      color: color,
      lastError: error,
    });
    console.info(error);
  };

  submit = () => {
    this.submitCall(this);
  };

  overrideWeight = (event) => {
    this.setState({ weightValue: event.target.value });
    this.weightValue = event.target.value;
    this.updateHook();
  };
  render = () => {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "",
      value,
      onChange: this.onChange,
      className: "form-control",
    };

    return (
      <Row onChange={this.updateHook}>
        <Col xs={3}>
          {this.inputMode ? (
            <div>Inventory ID</div>
          ) : (
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="IID">IID</InputGroup.Text>
              </InputGroup.Prepend>

              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                focusInputOnSuggestionClick={focusInputOnSuggestionClick}
                id="basic-example"
                ref={this.inventoryID}
                shouldItemRender={this.shouldItemRender}
              />
              {this.state.selected && (
                <div>
                  <FontAwesomeIcon
                    icon={faTimes}
                    color="red"
                    onClick={this.clearSelection}
                  />
                </div>
              )}
            </InputGroup>
          )}
        </Col>
        <Col xs={2}>
          {this.inputMode ? (
            <div>Metal</div>
          ) : (
            <InputGroup>
              <FormControl
                placeholder="Metal"
                aria-label="Metal"
                aria-describedby="Metal"
                value={this.state.metal}
                ref={this.metal}
                onChange={(e) => {
                  this.setState({ metal: e.target.value });
                }}
              />
            </InputGroup>
          )}
        </Col>

        <Col xs={3}>
          {this.inputMode ? (
            <div>Description</div>
          ) : (
            <InputGroup>
              <FormControl
                placeholder="Description"
                aria-label="Desc"
                aria-describedby="Description"
                value={this.state.desc}
                ref={this.desc}
              />
            </InputGroup>
          )}
        </Col>

        <Col xs={2}>
          {this.inputMode ? (
            <div>Weight</div>
          ) : (
            <InputGroup>
              <FormControl
                placeholder=""
                aria-label="Weight"
                aria-describedby="weight"
                value={this.state.weightValue}
                ref={this.weight}
                onChange={this.overrideWeight}
              />
              <InputGroup.Append>
                <InputGroup.Text id="weight">G</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          )}
        </Col>
        <Col xs={2} style={{ display: "none" }}>
          {this.inputMode ? (
            <div>Subtotal</div>
          ) : (
            <div>
              <InputGroup>
                <FormControl
                  placeholder="Subtotal"
                  aria-label="subtotal"
                  aria-describedby="subtotal"
                  value={GoldMasterRestAPI.formatMoney(
                    (this.state.weightValue ? this.state.weightValue : 0) *
                      this.state.goldprice
                  )}
                  ref={this.subtotal}
                  readOnly
                />
                <NumberFormat
                  value={
                    (this.state.weightValue ? this.state.weightValue : 0) *
                    this.state.goldprice
                  }
                  thousandSeparator={true}
                  prefix={"$"}
                  displayType={"text"}
                  style={{ float: "right" }}
                  decimalScale={2}
                />
              </InputGroup>
            </div>
          )}
        </Col>
        <Col xs={2}>
          {this.inputMode ? (
            <div>Weight (Tael)</div>
          ) : (
            <div>
              <InputGroup>
                <FormControl
                  placeholder="weight_tael"
                  aria-label="weight_tael"
                  aria-describedby="weight_tael"
                  value={GoldMasterRestAPI.formatWeight(
                    this.state.weightValue / 37.429
                  )}
                  ref={this.subtotal}
                  readOnly
                />
              </InputGroup>
            </div>
          )}
        </Col>

        {this.state.lastError &&
          this.state.lastError !== {} &&
          this.state.lastError.message && (
            <Col xs={12}>
              <Alert variant="danger">{this.state.lastError.message}</Alert>
            </Col>
          )}
      </Row>
    );
  };
}
