import React, { Component } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import moment from "moment-timezone";

const GoldMasterRestAPI = require("../../service/GoldMasterRestAPI.js").default;

const columns = [
  {
    name: "Inventory ID",
    selector: "_id",
    sortable: true,
    right: true,
  },
  {
    name: "Weight",
    selector: "weight",
    sortable: true,
    right: true,
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
    right: true,
  },
  {
    name: "Description",
    selector: "desc",
    sortable: true,
    right: true,
  },
  {
    name: "Update",
    selector: "updateAt",
    sortable: true,
    format: (row, index) => {
      return row.updateAt && moment(row.updateAt).tz("Asia/Hong_Kong").format("lll") || "N/A";
    },
  },
  {
    name: "Update By",
    selector: "updateBy",
    sortable: true,
    format: (row, index) => {
      return row.updateBy?.email || 'N/A';
    },
  },
  {
    name: "Action",
    selector: "status",
    sortable: true,
    right: true,
  },
];

createTheme("solarized", {
  text: {
    primary: "rgba(22, 0, 0, 0.87)",
    secondary: "rgba(22, 0, 0, 0.54)",
    disabled: "rgba(33, 0, 0, 0.38)",
  },
  background: {
    default: "#DDDDDD",
  },
});

export class ProductInv extends Component {
  constructor(props) {
    super(props);
    console.log(">>>>>>>");
    console.log(props);
    this.productID = props.data.ProductID;
    this.props = props;
    this.state = { data: [], productData: props };
  }

  componentDidMount() {
    this.getInventoryData(this.productID);
  }

  // 7:
  // createdAt: "2020-11-12T08:21:52.756Z"
  // productID: "nl009"
  // status: "ready"
  // updateAt: "2020-11-12T08:21:52.756Z"
  // weight: "40"
  // _id: "nl009-004"
  // __proto__: Object

  getInventoryData = (productID) => {
    // getData = (query) => {
    //   let { perPage, page, service, query_parameter } = query;

    GoldMasterRestAPI.getData({
      perPage: 40,
      page: 0,
      service: "inventory",
      query_parameter: { productID: productID },
    })
      .then((resp) => {
        console.log("Data:");
        console.log(resp);
        this.setState({ data: resp.data });
      })
      .catch((e) => {
        console.log("Error:");
        console.log(e);
      });
  };

  render() {
    var row = {};
    //this.state.data.;

    console.log(this.props);
    var inventoryRented = this.state.productData?.inventoryRented
      ? this.state.productData.inventoryRented
      : 0;
    var inventoryCount = this.state.productData?.inventoryCount
      ? this.state.productData.inventoryCount
      : 0;
    var ordered = this.state.productData?.ordered
      ? this.state.productData.ordered
      : 0;

    return (
      <div>
        {/* <div>
          <span className="badge badge-primary">Rent Out</span>
          <span className="badge badge-success">{ inventoryRented }</span>
          &nbsp;
          <span className="badge badge-primary">Inventory</span>
          <span className="badge badge-success">{ inventoryCount }</span>
          &nbsp;
          <span className="badge badge-primary">Ordered</span>
          <span className="badge badge-success">{ ordered }</span>
        </div>
        <div>
          <button className="btn btn-info" type="button">
            Order
          </button>
          <button className="btn btn-warning" type="button">
            Cancel
          </button>
          <button className="btn btn-primary" type="button">
            History
          </button>
        </div> */}
        <DataTable
          columns={ columns }
          data={ this.state.data }
          pagination
          striped
          highlightOnHover
          paginationTotalRows={ this.state.total }
          paginationServer
          // onChangeRowsPerPage={this.handlePerRowsChange}
          // onChangePage={this.handlePageChange}
          // expandableRows
          // expandableRowsComponent={
          //   <ProductInv productID={this.state.mapData.productID}
          theme="solarized"
        />
      </div>
    );
  }
}

export default ProductInv;
