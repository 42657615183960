import React from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
  Alert,
} from 'react-bootstrap';
import {
  faCoffee,
  faCircle,
  faSync,
  faCheckCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GoldMasterRestAPI = require('../service/GoldMasterRestAPI.js').default;

const InventoryEdit = (params) => {
  const { id } = useParams();
  React.useEffect(() => {
    GoldMasterRestAPI.getDataByID({
      id,
      service: 'inventory',
    }).then((data) => {
      console.info('====== Inventory ======');
      console.info(data);
      // rename _id to inventoryID
      const { _id: inventoryID, owner, labourFee, weight } = data;
      const fields = {
        inventoryID,
        owner,
        labourFee,
        weight,
      };
      Object.keys(fields).forEach((key) => {
        setValue(key, fields[key]);
      });
    });
  }, [id]);

  const { register, setValue, watch, handleSubmit } = useForm();
  const [submitStatus, setSubmitStatus] = React.useState({
    icon: faCircle,
    className: '',
    color: '',
  });

  const convertSubmitStatus = (status) => {
    let { icon, className, color } = {
      icon: faCircle,
      className: '',
      color: '',
    };
    switch (status) {
      case 'init':
        icon = faCircle;
        break;
      case 'ing':
        icon = faSync;
        className = 'fa-spin';
        break;
      case 'done':
        icon = faCheckCircle;
        color = 'green';
        break;
      case 'error':
        icon = faExclamationTriangle;
        color = 'red';
        break;
      default:
        icon = faCircle;
        break;
    }
    return { icon, className, color };
  };

  const [errorMessage, setErrorMessage] = React.useState(null);

  const onSubmit = async (data) => {
    setSubmitStatus(convertSubmitStatus('ing'));
    GoldMasterRestAPI.patch({ id, service: 'inventory', data })
      .then((d) => {
        console.log(d);
        setSubmitStatus(convertSubmitStatus('done'));
      })
      .catch((d) => {
        console.log(d);
        setErrorMessage(d.message);
        setSubmitStatus(convertSubmitStatus('error'));
      });
  };

  return (
    <>
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={4}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id='InventoryID'>
                    InventoryID
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <FormControl
                  placeholder='Inventory ID'
                  aria-label='Inventory ID'
                  aria-describedby='InventoryID'
                  {...register('inventoryID')}
                  readOnly
                />
              </InputGroup>
            </Col>
            <Col xs={2}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id='OwnerID'>Owner</InputGroup.Text>
                </InputGroup.Prepend>

                <FormControl
                  placeholder='Owner'
                  aria-label='Owner ID'
                  aria-describedby='OwnerID'
                  {...register('owner')}
                />
              </InputGroup>
            </Col>
            <Col xs={2}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id='LabourFee'>Labour Fee</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder='0'
                  aria-label='Labour Fee'
                  aria-describedby='LabourFee'
                  {...register('labourFee', { required: true })}
                />
              </InputGroup>
            </Col>
            <Col xs={2}>
              <InputGroup>
                <FormControl
                  placeholder='Weight'
                  aria-label='Weight'
                  aria-describedby='basic-addon2'
                  {...register('weight', { required: true })}
                />
                <InputGroup.Append>
                  <InputGroup.Text id='basic-addon2'>Gram</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col xs={2}>
              <button type='submit'>Submit</button>
              <FontAwesomeIcon
                icon={submitStatus.icon}
                className={submitStatus.className}
                color={submitStatus.color}
              />
            </Col>
            {errorMessage && (
              <Col xs={12}>
                <Alert variant='danger'>{errorMessage}</Alert>
              </Col>
            )}
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default InventoryEdit;
