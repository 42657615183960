import React, { Component } from "react";
import { Row, Col, InputGroup, FormControl, Form } from "react-bootstrap";
import {
  faCircle,
  faSync,
  faCheckCircle,
  faExclamationTriangle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Alert from "react-bootstrap/Alert";
import Autosuggest from "react-autosuggest";
import isMobile from "ismobilejs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import NumberFormat from "react-number-format";
import jquery from "jquery";
import $ from "jquery";
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";

const GoldMasterRestAPI = require("../../service/GoldMasterRestAPI.js").default;

//https://react-select.com/async

const getOptions = (input) => {
  let data = [];

  return data;
};

const focusInputOnSuggestionClick = !isMobile.any;

const renderSuggestion = (suggestion) => <span>{suggestion.label}</span>;
export class SaleNoteInputRow extends Component {
  state = { icon: "", suggestions: [] };

  constructor(props) {
    super(props);

    let {
      icon,
      submit,
      mykey,
      goldPrice,
      updated,
      inputMode,
      goldPricePerTael,
      defaultValues = {},
      checkIsDuplcated
    } = props;
    console.log(JSON.stringify(defaultValues || {}));
    this.state.inventoryID_val = defaultValues?.inventoryIdValue || "";

    this.state.icon = icon;
    this.state.value = defaultValues?.inventoryIdValue || "";
    this.state.suggestions = [];
    this.state.unitPriceValue = defaultValues?.unitPriceValue || 0.0;
    this.state.metal = defaultValues?.metal || "AU999.9";
    this.state.subTotalValue = defaultValues?.subTotal || "0.00";
    this.state.discount = Number(defaultValues?.discount) || 0.0;
    this.state.show = true;
    this.state.goldPricePerTael = goldPricePerTael;

    const commissionEnable = defaultValues.Commission || false;

    this.state.commissionEnable = commissionEnable;
    this.state.commissionName = "Commission";
    this.state.commissionFee =
      (commissionEnable && defaultValues.Commission.unitPriceValue) || 0;
    this.state.commissionDiscount =
      (commissionEnable && defaultValues.Commission.discount) || 0;
    this.state.commissionSubTotal =
      (commissionEnable && defaultValues.Commission.subTotal) || 0;
    this.state.commissionDesc =
      (commissionEnable && defaultValues.Commission.desc) || 0;

    const laborEnable = defaultValues.Labour || false;
    this.state.laborEnable = laborEnable;
    this.state.laborName = "Labor Fee";
    this.state.laborFee =
      (laborEnable && defaultValues.Labour.unitPriceValue) || 0;
    this.state.laborDiscount =
      (laborEnable && defaultValues.Labour.discount) || 0;
    this.state.laborSubTotal =
      (laborEnable && defaultValues.Labour.subTotal) || 0;
    this.state.laborDesc = (laborEnable && defaultValues.Labour.desc) || 0;

    this.state.productID = defaultValues?.productIdValue || "";
    this.state.weight = defaultValues?.weight || "0.0";
    this.state.weightTael = "0.0";
    this.state.desc = defaultValues?.desc || "";

    this.discountValue = 0.0;
    this.unitPriceValue = 0.0;

    this.className = "";
    this.submitCall = submit;
    this.key = mykey;

    this.updateHook = props.updated;

    this.state.goldprice = goldPrice;
    this.inventoryID = React.createRef();
    this.unitPrice = React.createRef();
    this.desc = React.createRef();
    this.metal = React.createRef();
    this.productID = React.createRef();
    this.discount = React.createRef();
    this.weight = React.createRef();
    this.subTotal = React.createRef();
    this.commissionIID = React.createRef();
    this.commissionName = React.createRef();
    this.commissionFee = React.createRef();
    this.commissionDiscount = React.createRef();
    this.commissionDesc = React.createRef();
    this.commissionSubTotal = React.createRef();
    this.laborIID = React.createRef();
    this.laborName = React.createRef();
    this.laborFee = React.createRef();
    this.laborDiscount = React.createRef();
    this.laborDesc = React.createRef();
    this.laborSubTotal = React.createRef();

    this.inputMode = inputMode;
    //this.setState({ icon: icon });


    this.checkIsDuplcated = checkIsDuplcated;
  }

  getSuggestionValue = (suggestion) => {
    //return suggestion.value;

    console.log("Suggestion");
    console.log(suggestion);
    console.log("Gold Price:" + this.state.goldprice);

    const supposedUnitPrice =
      this.formatWeight(suggestion.weight / 37.429) *
      this.props.goldPricePerTael;

    this.setState(
      {
        selected: suggestion,
        //      unitPriceValue: suggestion.unitPrice,
        inventoryID_val: suggestion._id,
        productIDValue: suggestion.productID,
        productID: suggestion.productID,
        unitPriceValue: supposedUnitPrice,
        weight: suggestion.weight,
        weightTael: this.formatWeight(suggestion.weight / 37.429),
        subtotalValue: this.updateSubtotalValue(),
        commissionFee: (supposedUnitPrice * 0.02).toFixed(2) || 0,
        commissionDesc: `Commission (${suggestion._id})`,
        commissionDiscount: 0,
        laborFee: suggestion.labourFee || 0,
        laborDesc: `Labor Fee (${suggestion._id})`,
        laborDiscount: 0,
      },

      () => {
        GoldMasterRestAPI.getDataByID({
          id: suggestion.productID,
          service: "product",
        }).then((p) => {
          console.log("Product ID:--" + suggestion.productID);
          console.log(p.Metal);
          console.log(p);
          this.setState(
            {
              desc: p.ProductName,
              metal: p.Metal,
            },
            () => {
              // this.updateSubtotalValue();
              // this.updateHook(this);

              this.inventoryID_val = suggestion._id;
              this.updateSubtotalValue(() => this.updateHook(this));
              //   this.unitPriceValue = suggestion.unitPrice;
              //   this.productID = suggestion.productID;
              //   this.metal_val = suggestion.metal;
            }
          );
        });
      }
    );
    // this.updateSubtotalValue()
    // console.log(this.state.subTotalValue)
    // //   this.unitPriceValue = suggestion.unitPrice;
    // //   this.productID = suggestion.productID;
    // this.inventoryID_val = suggestion._id;
    // //   this.metal_val = suggestion.metal;

    // this.updateHook(this);

    return suggestion.value;
  };
  onIDChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onChange = (e) => {
    console.log("onChange");
    console.log(e);
    console.log(e.target);
    console.log(e.target.value);

    console.log("Sub Total Calculation");
    let subT = this.formatMoney(
      this.unitPrice.current.value * ((100 - this.discount.current.value) / 100)
    );

    console.log(subT);
    this.setState(
      {
        subTotalValue: subT,
      },
      () => {
        this.updateHook();
      }
    );
    return e;
  };

  onSuggestionsFetchRequested = ({ value }) => {
    GoldMasterRestAPI.getData({
      service: "inventory",
      perPage: 100,
      query_parameter: { _id: { $search: value }, status: "ready" },
    }).then((apiResult) => {
      console.log("------ product api call");
      console.log(apiResult);
      let result = [];
      apiResult.data.map((res) => {

        console.log(res);
        if (!this.checkIsDuplcated(res._id)) {
          result.push({ ...res, label: res._id, value: res._id });
        }


//        result.push({ ...res, label: res._id, value: res._id });
      });
      // { label: "1", value: "1", unitPrice: 1.0 },
      // { label: "1cccccc", value: "1112", unitPrice: 2.0 },
      // { label: "bbbbbbbb1", value: "bbb", unitPrice: 3.0 },
      this.setState({
        suggestions: result,
      });
    });
  };

  getSubtotal = () => {
    return (
      parseFloat(this.state.subTotalValue) +
      ((this.state.laborEnable && parseFloat(this.state.laborSubTotal)) || 0) +
      ((this.state.commissionEnable &&
        parseFloat(this.state.commissionSubTotal)) ||
        0)
    );
  };

  getLabourSubtotal = () => {
    return parseFloat(this.state.laborSubTotal || 0);
  };
  getCommissionSubtotal = () => {
    return parseFloat(this.state.commissionSubTotal || 0);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  clearSelection = () => {
    this.setState(
      {
        selected: null,
        value: "",
        unitPriceValue: "",
        commissionName: "Commission",
        commissionFee: 0,
        commissionDiscount: 0,
        commissionSubTotal: 0,
        commissionDesc: "",
        laborName: "Labor Fee",
        laborDesc: "",
        laborFee: 0,
        laborDiscount: 0,
        laborSubTotal: 0,
        inventoryID_val: "",
        productID: "",
        // weighTael: "0",
        // metal: 'AU999.9',
        weight: 0,
      },
      () => {
        // invoke the setState again to calculate the latest weight
        // only works when setting in the callback (not the first call)
        this.setState(
          {
            weightTael: this.formatWeight(this.state.weight / 37.429),
          },
          () => {
            this.value = "";
            this.updateSubtotalValue(() => this.updateHook(this));
          }
        );
      }
    );
  };

  getKey = () => {
    return this.key;
  };

  getData = () => {
    //console.log("get result froim child");

    //state value are not updated yet
    let result = {};
    result.inventoryIdValue = this.inventoryID.current.input.value;
    result.productIdValue = this.productID.current.value;
    result.desc = this.desc.current.value;
    result.metal = this.metal.current.value;
    result.weight = this.state.weight;
    if (!result.weight) {
      result.weight = "0";
      result.metal = "";
    }

    result.weightTael = this.state.weightTael;

    result.unitPriceValue = this.state.unitPriceValue;
    result.discount = this.discount.current.value;
    this.updateSubtotalValue();
    result.subTotal = this.subTotalValue;

    result.commission =
      (this.state.commissionEnable && {
        desc: this.state.commissionDesc,
        discount: this.state.commissionDiscount,
        subTotal: this.state.commissionSubTotal,
        unitPriceValue: this.formatMoney(this.state.commissionFee),
        metal: "",
        productIdValue: "",
        weight: "0.0",
        inventoryIdValue: "",
      }) ||
      null;
    result.laborFee =
      (this.state.laborEnable && {
        desc: this.state.laborDesc,
        discount: this.state.laborDiscount,
        subTotal: this.state.laborSubTotal,
        unitPriceValue: this.formatMoney(this.state.laborFee),
        metal: "",
        productIdValue: "",
        weight: "0.0",
        inventoryIdValue: "",
      }) ||
      null;

    return result;
  };

  setIcon = (icon) => {
    this.setState({ icon: icon });
  };

  setLastError = (error) => {
    this.lastError = error;
  };
  setStatus = (status) => {
    let icon = faCircle;
    let className = "";
    let color = "";
    let error = this.lastError ? this.lastError : {};
    switch (status) {
      case "init":
        icon = faCircle;
        break;
      case "ing":
        icon = faSync;
        className = "fa-spin";
        break;
      case "done":
        icon = faCheckCircle;
        color = "green";
        break;

      case "error":
        icon = faExclamationTriangle;
        color = "red";
        break;
      default:
        icon = faCircle;
        break;
    }
    this.setState({
      icon: icon,
      className: className,
      color: color,
      lastError: error,
    });
    console.info(error);
  };

  submit = () => {
    this.submitCall(this);
  };

  updateSubtotalValue = (cb) => {
    this.subTotalValue = this.formatMoney(
      this.state.unitPriceValue * ((100 - this.discountValue) / 100)
    );

    const laborSubTotal = this.formatMoney(
      Number.parseFloat(this.state.laborFee) *
        ((100 - this.state.laborDiscount) / 100)
    );
    const commissionSubTotal = this.formatMoney(
      Number.parseFloat(this.state.commissionFee) *
        ((100 - this.state.commissionDiscount) / 100)
    );

    if (cb) {
      this.setState(
        {
          subTotalValue: this.subTotalValue,
          laborSubTotal,
          commissionSubTotal,
        },
        cb
      );
    } else
      this.setState({
        subTotalValue: this.subTotalValue,
        laborSubTotal,
        commissionSubTotal,
      });
    return this.subTotalValue;
  };

  overrideunitPrice = (event) => {
    this.setState(
      {
        unitPriceValue: event.target.value,
        subTotalValue: this.updateSubtotalValue(),
        // also update commissionFee when Unit Price changes
        commissionFee: (event.target.value * 0.02).toFixed(2),
      },
      () => {
        this.updateSubtotalValue(() => this.updateHook(this));
      }
    );
    this.unitPriceValue = event.target.value;
  };

  overrideCommissionFee = (event) => {
    const newCommissionFee = event.target.value;
    this.setState(
      {
        commissionFee: newCommissionFee,
      },
      () => {
        this.updateSubtotalValue(() => this.updateHook(this));
      }
    );
  };

  overrideLaborFee = (event) => {
    const newLaborFee = event.target.value;
    this.setState(
      {
        laborFee: newLaborFee,
      },
      () => {
        this.updateSubtotalValue(() => this.updateHook(this));
      }
    );
  };

  changeDiscount = (event) => {
    this.setState(
      {
        discount: event.target.value,
        subTotalValue: this.updateSubtotalValue(),
      },
      () => {
        this.updateSubtotalValue(() => this.updateHook(this));
      }
    );
    this.discountValue = event.target.value;
  };

  formatMoney = (amount) => {
    return Number.parseFloat(amount).toFixed(2);
  };

  formatWeight = (amount) => {
    return Number.parseFloat(amount).toFixed(3);
  };

  hide = () => {
    this.productID.current.value = null;
    this.setState({ show: false });
  };

  render = () => {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "",
      value,
      onChange: this.onIDChange,
      className: "form-control",
    };

    return (
      <>
        <Row
          onChange={this.onChange}
          style={{
            display: this.state.show ? null : "none",
            paddingBottom: "10px",
          }}
        >
          <Col xs={3}>
            {this.inputMode ? (
              <div>
                <div>Inventory ID</div>
                <div>Product ID</div>
              </div>
            ) : (
              <div>
                <div>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="Product">IID</InputGroup.Text>
                    </InputGroup.Prepend>

                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={
                        this.onSuggestionsFetchRequested
                      }
                      onSuggestionsClearRequested={
                        this.onSuggestionsClearRequested
                      }
                      getSuggestionValue={this.getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      inputProps={inputProps}
                      focusInputOnSuggestionClick={focusInputOnSuggestionClick}
                      id="basic-example"
                      ref={this.inventoryID}
                    />
                    {this.state.selected && (
                      <div>
                        <FontAwesomeIcon
                          icon={faTimes}
                          color="red"
                          onClick={this.clearSelection}
                        />
                      </div>
                    )}
                  </InputGroup>
                </div>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="Product">PID</InputGroup.Text>
                  </InputGroup.Prepend>

                  <FormControl
                    placeholder="Product ID"
                    aria-label=""
                    aria-describedby=""
                    value={this.state.productID}
                    ref={this.productID}
                    onChange={(e) => {
                      this.setState({ productID: e.target.value });
                    }}
                  />
                  {this.state.selected && (
                    <div>
                      <FontAwesomeIcon
                        icon={faTimes}
                        color="red"
                        onClick={this.clearSelection}
                      />
                    </div>
                  )}
                </InputGroup>
              </div>
            )}
          </Col>
          <Col xs={2}>
            {this.inputMode ? (
              <div>Description</div>
            ) : (
              <InputGroup>
                <FormControl
                  placeholder="Description"
                  aria-label="Desc"
                  aria-describedby="Description"
                  value={this.state.desc}
                  ref={this.desc}
                  as="textarea"
                  rows={2}
                />
              </InputGroup>
            )}
          </Col>

          <Col xs={2}>
            {this.inputMode ? (
              <div>
                <div>Metal</div>
                <div>Weight</div>
              </div>
            ) : (
              <div>
                <InputGroup>
                  <FormControl
                    placeholder="Metal"
                    aria-label="Metal"
                    aria-describedby="Metal"
                    value={this.state.metal}
                    ref={this.metal}
                    onChange={(e) => {
                      this.setState({ metal: e.target.value });
                    }}
                  />
                </InputGroup>

                <InputGroup>
                  <FormControl
                    placeholder="G"
                    aria-label="weight"
                    aria-describedby="weight"
                    value={this.state.weight}
                    onChange={(e) =>
                      this.setState({
                        weight: e.target.value,
                        weightTael: this.formatWeight(e.target.value / 37.429),
                      })
                    }
                    ref={this.weight}
                  />

                  <span>({this.state.weightTael}兩)</span>
                </InputGroup>
              </div>
            )}
          </Col>

          <Col xs={2}>
            {this.inputMode ? (
              <div>
                <div>UnitPrice</div>
                <div>Discount</div>
              </div>
            ) : (
              <div>
                <InputGroup>
                  <FormControl
                    placeholder="UnitPrice"
                    aria-label="UnitPrice"
                    aria-describedby="UnitPrice"
                    value={this.state.unitPriceValue}
                    ref={this.unitPrice}
                    onChange={this.overrideunitPrice}
                  />
                </InputGroup>
                <InputGroup>
                  <FormControl
                    placeholder="Discount"
                    aria-label="Discount"
                    aria-describedby="Discount"
                    value={this.state.discount}
                    ref={this.discount}
                    onChange={this.changeDiscount}
                  />
                  <InputGroup.Prepend>
                    <InputGroup.Text>% Disc</InputGroup.Text>
                  </InputGroup.Prepend>
                </InputGroup>
              </div>
            )}
          </Col>

          <Col xs={2}>
            {this.inputMode ? (
              <div>Subtotal</div>
            ) : (
              <InputGroup className="text-right">
                <NumberFormat
                  value={this.state.subTotalValue}
                  thousandSeparator={true}
                  prefix={"$"}
                  displayType={"text"}
                  style={{ float: "right", width: "100%" }}
                  decimalScale={2}
                />

                <FormControl
                  placeholder="Subtotal"
                  aria-label="SubTotal"
                  aria-describedby="SubTotal"
                  value={this.state.subTotalValue}
                  ref={this.subTotal}
                  style={{ display: "none" }}
                />
              </InputGroup>
            )}

            {/* <FontAwesomeIcon icon={faTimes} onClick={this.hide} color="red" /> */}
          </Col>
          {/* commission (Enable Commission) */}
          {!this.inputMode && this.state.inventoryID_val && (
            <>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Enable Commission"
                  style={{ float: "left" }}
                  checked={this.state.commissionEnable}
                  onChange={() => {
                    this.setState({
                      commissionEnable: !this.state.commissionEnable,
                    });
                    console.log(this.state.commissionEnable);
                  }}
                />
              </Col>
            </>
          )}

          {!this.inputMode &&
            this.state.inventoryID_val &&
            this.state.commissionEnable && (
              <>
                <Col xs={3}></Col>
                <Col xs={2}>
                  <InputGroup>
                    <FormControl
                      placeholder="Description"
                      aria-label="Desc"
                      aria-describedby="Description"
                      value={this.state.commissionDesc}
                      ref={this.commissionDesc}
                      as="textarea"
                      rows={2}
                    />
                  </InputGroup>
                </Col>
                <Col xs={2}></Col>
                <Col xs={2}>
                  <InputGroup>
                    <FormControl
                      as="input"
                      placeholder="UnitPrice"
                      aria-label="UnitPrice"
                      aria-describedby="UnitPrice"
                      value={this.state.commissionFee}
                      onChange={this.overrideCommissionFee}
                      ref={this.commissionFee}
                    />
                  </InputGroup>
                  <InputGroup>
                    <FormControl
                      as="input"
                      placeholder="Discount"
                      aria-label="Discount"
                      aria-describedby="Discount"
                      value={this.state.commissionDiscount}
                      ref={this.commissionDiscount}
                      onChange={(e) => {
                        this.state.commissionDiscount = e.target.value;
                        this.setState(
                          {
                            commissionSubTotal:
                              this.state.commissionFee *
                              ((100 - e.target.value) / 100),
                          },
                          () => {
                            this.updateSubtotalValue(() =>
                              this.updateHook(this)
                            );
                          }
                        );
                      }}
                    />
                    <InputGroup.Prepend>
                      <InputGroup.Text>% Disc</InputGroup.Text>
                    </InputGroup.Prepend>
                  </InputGroup>
                </Col>
                <Col xs={2}>
                  <InputGroup className="text-right">
                    <NumberFormat
                      value={this.state.commissionSubTotal}
                      thousandSeparator={true}
                      prefix={"$"}
                      displayType={"text"}
                      style={{ float: "right", width: "100%" }}
                      decimalScale={2}
                    />

                    <FormControl
                      placeholder="Subtotal"
                      aria-label="SubTotal"
                      aria-describedby="SubTotal"
                      value={this.state.commissionSubTotal}
                      ref={this.commissionSubTotal}
                      style={{ display: "none" }}
                    />
                  </InputGroup>
                </Col>
              </>
            )}
          {/* labor fee (enable labor fee) */}
          {!this.inputMode && this.state.inventoryID_val && (
            <>
              <Col xs={12}>
                <Form.Check
                  inline
                  label="Enable Labor Fee"
                  style={{ float: "left" }}
                  checked={this.state.laborEnable}
                  onChange={() => {
                    this.setState({
                      laborEnable: !this.state.laborEnable,
                    });
                    console.log(this.state.laborEnable);
                  }}
                />
              </Col>
            </>
          )}
          {/* labor fee */}
          {!this.inputMode &&
            this.state.inventoryID_val &&
            this.state.laborEnable && (
              <>
                <Col xs={3}></Col>
                <Col xs={2}>
                  <InputGroup>
                    <FormControl
                      placeholder="Description"
                      aria-label="Desc"
                      aria-describedby="Description"
                      value={this.state.laborDesc}
                      ref={this.laborDesc}
                      as="textarea"
                      rows={2}
                    />
                  </InputGroup>
                </Col>
                <Col xs={2}></Col>
                <Col xs={2}>
                  <InputGroup>
                    <FormControl
                      as="input"
                      placeholder="UnitPrice"
                      aria-label="UnitPrice"
                      aria-describedby="UnitPrice"
                      value={this.state.laborFee}
                      onChange={this.overrideLaborFee}
                      ref={this.laborFee}
                    />
                  </InputGroup>
                  <InputGroup>
                    <FormControl
                      placeholder="Discount"
                      aria-label="Discount"
                      aria-describedby="Discount"
                      value={this.state.laborDiscount}
                      ref={this.laborDiscount}
                      onChange={(e) => {
                        this.state.laborDiscount = e.target.value;
                        this.setState(
                          {
                            laborSubTotal:
                              this.state.laborFee *
                              ((100 - e.target.value) / 100),
                          },
                          () => {
                            this.updateSubtotalValue(() =>
                              this.updateHook(this)
                            );
                          }
                        );
                      }}
                    />

                    <InputGroup.Prepend>
                      <InputGroup.Text>% Disc</InputGroup.Text>
                    </InputGroup.Prepend>
                  </InputGroup>
                </Col>
                <Col xs={2}>
                  <InputGroup className="text-right">
                    <NumberFormat
                      value={this.state.laborSubTotal}
                      thousandSeparator={true}
                      prefix={"$"}
                      displayType={"text"}
                      style={{ float: "right", width: "100%" }}
                      decimalScale={2}
                    />

                    <FormControl
                      placeholder="Subtotal"
                      aria-label="SubTotal"
                      aria-describedby="SubTotal"
                      value={this.state.laborSubTotal}
                      ref={this.laborSubTotal}
                      style={{ display: "none" }}
                    />
                  </InputGroup>
                </Col>
              </>
            )}
          {this.state.lastError &&
            this.state.lastError !== {} &&
            this.state.lastError.message && (
              <Col xs={12}>
                <Alert variant="danger">{this.state.lastError.message}</Alert>
              </Col>
            )}
        </Row>

        {/* <div>{this.inventoryID.current?.input.value }</div> */}
      </>
    );
  };
}
