import { faMinusSquare, faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment-timezone";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

const GoldMasterRestAPI = require("../../service/GoldMasterRestAPI.js").default;

//https://ellismin.com/2020/05/next-pagination/

const perPageOptions = [10, 15, 20, 25, 30];

export default function SaleNoteList() {
  const [sortDirection, setSortDirection] = React.useState(1);
  const [sortSelector, setSortSelector] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(20);
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [limit, setLimit] = React.useState(20);
  const [skip, setSkip] = React.useState(0);
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    getSalesNoteData();
  }, [sortDirection, sortSelector, currentPage, perPage]);

  const handleOnSort = (column, sortDirection) => {
    // https://github.com/jbetancur/react-data-table-component#sorting
    const { selector } = column;

    setSortDirection(sortDirection === "asc" ? 1 : -1);
    setSortSelector(selector);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (perPage, page) => {
    setCurrentPage(page);
    setPerPage(perPage);
  };

  const getSalesNoteData = () => {
    let show_page = currentPage ? currentPage : 1;

    let skip = (show_page - 1) * perPage;

    // this.setState({ loading: true });
    setIsLoading(true);

    GoldMasterRestAPI.getData({
      page: currentPage,
      perPage: perPage,
      service: "salesnote",
      // https://github.com/gaearon/feathers-docs/blob/master/databases/pagination.md#sort
      sort: sortSelector ? { [sortSelector]: sortDirection } : null,
    }).then((resp) => {
      const { data, limit, skip, total } = resp;
      setIsLoading(false);
      setPerPage(perPage);
      setData(data);
      setLimit(limit);
      setSkip(skip);
      setTotal(total);
    });
  };

  const deleteSalesNote = (row) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: `Are you sure to delete Product ${row._id}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            GoldMasterRestAPI.remove({
              id: row._id,
              service: "salesnote",
            }).then((result) => {
              getSalesNoteData();
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const columns = [
    {
      name: "Invoice No",
      selector: "id",
      sortable: true,
      right: true,
    },
    {
      name: "Client No.",
      selector: "order.field_clientno",
      sortable: true,
      right: true,
    },
    {
      name: "Client Name",
      selector: "order.field_client",
      sortable: true,
      right: true,
    },
    {
      name: "Mobile",
      selector: "order.field_mobile",
      sortable: true,
      right: true,
      wrap: true,
    },
    {
      name: "Total",
      selector: "order.total",
      sortable: true,
      right: true,
      wrap: true,
    },
    {
      name: "Create",
      selector: "createdAt",
      sortable: true,
      format: (row, index) => {
        return (
          (row.createdAt &&
            moment(row.createdAt).tz("Asia/Hong_Kong").format("lll")) ||
          "N/A"
        );
      },
    },
    {
      name: "Create By",
      selector: "createdBy",
      sortable: true,
      format: (row, index) => {
        return row.createdBy?.email || "N/A";
      },
    },
    {
      name: "Update",
      selector: "updateAt",
      sortable: true,
      format: (row, index) => {
        return (
          (row.updateAt &&
            moment(row.updateAt).tz("Asia/Hong_Kong").format("lll")) ||
          "N/A"
        );
      },
    },
    {
      name: "Update By",
      selector: "updateBy",
      sortable: true,
      format: (row, index) => {
        return row.updateBy?.email || "N/A";
      },
    },
    {
      name: "PDF Link",
      selector: "pdfLink",
      // sortable: true,
      format: (row, index) => {
        return (
          (row.pdfLink && (
            <a href={row.pdfLink} target="_blank">
              PDF
            </a>
          )) || <div></div>
        );
      },
    },
    {
      name: "Action",
      selector: "status",
      format: (row, index) => {
        return (
          <div>
            <FontAwesomeIcon
              size="lg"
              icon={faMinusSquare}
              color="red"
              onClick={() => deleteSalesNote(row)}
              style={{ display: "none" }}
            />
            <Link to={`/SaleNoteEdit/${row._id}`}>
              <FontAwesomeIcon
                size="lg"
                icon={faPenSquare}
                style={{ marginLeft: "5px" }}
                color="red"
              />
            </Link>
          </div>
        );
      },
    },
  ];

  /*
            <FontAwesomeIcon
              size="lg"
              icon={faMinusSquare}
              color="red"
              onClick={() => deleteSalesNote(row)}
              style={{ display: "none" }}
            />
            <Link to={`/SaleNoteEdit/${row._id}`}>
              <FontAwesomeIcon
                size="lg"
                icon={faPenSquare}
                style={{ marginLeft: "5px" }}
                color="red"
              />
            </Link>
*/

  return (
    <>
      <h1>Sales Notes: {total} Entries</h1>
      <DataTable
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleOnSort}
        columns={columns}
        data={data}
        // expandableRowsComponent={ <ProductInv /> }
        paginationTotalRows={total}
        paginationPerPage={perPage}
        paginationRowsPerPageOptions={perPageOptions}
        dummy={0}
        sortServer
        pagination
        striped
        highlightOnHover
        paginationServer
        // expandableRows
      />
    </>
  );
}
