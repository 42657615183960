import React, { Component } from "react";
import { Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { Button, Container, Form } from "react-bootstrap";
import { faCircle, faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons";

import { OrderInputRow } from "../RentalNote/RentalNoteInputRow";
import { OrderNoteItem } from "./OrderNoteItem";
import { faPencilRuler } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";
import NumberFormat from "react-number-format";
import { faGoogleDrive } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStore,
  faUserClock,
  faQuestionCircle,
  faSync,
  faCheckCircle,
  faExclamationTriangle,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import jquery from "jquery";
import $ from "jquery";
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";

const GoldMasterRestAPI = require("../../service/GoldMasterRestAPI.js").default;
const cryptoRandomString = require("crypto-random-string");
var QRCode = require("qrcode.react");

export class OrderNoteEdit extends Component {
  constructor() {
    setDefaultLocale("en");
    super();
    let i = 0;

    // //create enough cells for input
    // for (i = 0; i < 3; i++) {
    //   let ref = React.createRef();
    //   this.state.cells.push({ key: i, icon: faCircle, ref: ref });
    // }
    //this.state.cells = [{ key: 0, icon: faCircle }];

    this.field_client = React.createRef();
    this.field_mobile = React.createRef();
    this.field_details = React.createRef();
    this.override_total = React.createRef();

    this.field_clientno = React.createRef();
    this.field_mobile = React.createRef();
    this.field_details = React.createRef();
    this.field_rentfrom = React.createRef();
    this.field_rentto = React.createRef();
    this.field_staffno = React.createRef();
    this.field_iddoc = React.createRef();
    this.field_staffno = React.createRef();
    this.field_paymenttype = React.createRef();
    this.field_invoiceno = React.createRef();
    this.field_total = React.createRef();
    this.field_deposit_total = React.createRef();
    this.field_rentdiscount = React.createRef();

    this.state = {
      cells: [],
      total: 0,
      status: "draft",
      goldPrice: 0,
      goldPricePerTael: 0,
      discount: 0,
      deposit_discount: 0.0,

      discount_percent: 0,
      deposit_discount_percent: 0,

      totalWeightTael: 0,
      totalWeight: 0,
    };
  }

  ensureEmptyRows = () => {
    console.log("EmptyRow");

    let filled = this.state.cells.filter((elm) => {
      //console.log(elm.ref.current.productID.current.input.value);
      return elm.ref.current.productID.current.input.value;
    }).length;

    console.log(filled);

    if (this.state.cells.length - filled < 1) {
      let ref = React.createRef();
      let i = this.state.cells.length + 1;
      this.state.cells.push({ key: i, icon: faCircle, ref: ref });
    }
  };

  getNoteFromApi = (id) => {
    GoldMasterRestAPI.getDataByID({ id, service: "rental-invoice" }).then(
      ({ order, ...res }) => {
        console.log(JSON.stringify({ order, ...res }));
        this.setState({
          cells: order.row.map((row, index, arr) => {
            let ref = React.createRef();
            let i = index;
            return { key: i, icon: faCircle, ref: ref, defaultValues: row };
          }),
          total: Number(order.total),
          // status: order.status,
          goldPrice: order.field_goldprice,
          // // goldPricePerTael: 0,
          // discount: order.field_discount_percentage.replace('%', ''),
          // deposit_discount_percent: 0,
          // discount_percent: 0,
          discount_flat: order.field_discount,
          totalWeight: order.totalWeight,
          totalWeightTael: order.field_totaltael,
          clientNo: order.field_clientno,
          clientName: order.field_client,
          clientPhone: order.field_mobile,
          clientDetail: order.field_details,
          field_rentfrom: moment(order.field_rentfrom).toDate(),
          field_rentto: moment(order.field_rentto).toDate(),
          invoiceno: order.field_invoiceno,
          iddoc: order.field_iddoc,
          staffno: order.field_staffno,
          paymenttype: order.field_paymenttype,
          _oldGoldPrice: order.field_goldprice,
          deposit_discount_percent:
            (order.field_deposit_discount_percentage &&
              order.field_deposit_discount_percentage.replace("%", "")) ||
            "",
          deposit_discount: order.field_deposit_discount,
        });
        console.log(order.field_discount_flat);
      }
    );
  };

  componentDidMount() {
    //get the latest price
    this.getPriceFromApi();
    const { id } = this.props.match.params;
    this.getNoteFromApi(id);
    this.updateInteval = setInterval(() => {
      this.getPriceFromApi();
    }, 60000 * 10);
  }
  componentWillUnmount() {
    clearInterval(this.updateInteval);
  }

  getPriceFromApi() {
    console.log(GoldMasterRestAPI.getGoldPrice());
    GoldMasterRestAPI.getGoldPrice()
      .then((result) => {
        this.setState({
          goldPrice: Number.parseFloat((result.minPrice - 50) / 37.429).toFixed(
            3
          ),
        });
        this.setState({ goldPricePerTael: result.minPrice - 50 });

        console.info(result);
      })
      .catch((error) => {
        console.info(error);
      });
  }

  submitToAPI = (payload) => {
    this.setState({ workingState: "running" });

    let rows = this.state.cells
      .map((cell) => {
        let c = cell.ref.current;

        return c.getData();
      })
      .filter((v) => {
        return v.productIdValue;
      });

    let order = {};

    var totalWeight = 0.0;
    rows.forEach(function (row, index) {
      row.index = index + 1;
      totalWeight += parseFloat(row.weight);
    });

    var totalWeightTael = 0.0;
    rows.forEach(function (row, index) {
      row.index = index + 1;
      console.log(row);
      totalWeightTael += parseFloat(row.weightTael);
    });

    order.row = rows;

    console.log("Rows");
    console.log(rows);

    /*
    order.total = rows
      .map((v) => v.subtotal)
      .reduce((acc, v) => parseFloat(acc) + parseFloat(v), 0);
*/

    order.field_client = this.field_client.current.value;
    order.field_clientno = this.field_clientno.current.value;
    order.field_mobile = this.field_mobile.current.value;
    order.field_details = this.field_details.current.value;
    order.field_goldprice = this.state.goldPricePerTael;

    order.field_rentfrom = this.state.field_rentfrom;
    order.field_rentto = this.state.field_rentto;
    order.field_staffno = this.field_staffno.current.value;
    order.field_iddoc = this.field_iddoc.current.value;
    order.field_paymenttype = this.field_paymenttype.current.value;
    order.field_invoiceno = this.field_invoiceno.current.value;

    order.field_discount = this.field_rentdiscount.current.value
      ? this.field_rentdiscount.current.value
      : 0.0;
    order.field_discount_percentage = 0.0;

    order.field_deposit_discount_percentage =
      "" + parseFloat(this.state.deposit_discount_percent).toFixed(2) + "%";

    order.field_deposit_discount =
      "" + parseFloat(this.state.deposit_discount).toFixed(2) + "";

    // TODO overrided by Manual Input
    order.total = this.field_total.current.value;

    order.date = moment(new Date()).format("DD-MM-YYYY");

    console.log("totalWeight:");
    console.log(totalWeight);
    order.totalWeight = parseFloat(totalWeight).toFixed(3);
    order.field_totaltael = parseFloat(totalWeightTael).toFixed(3);
    order.field_total_deposit = parseFloat(
      this.field_deposit_total.current.value
    ).toFixed(1);

    if (Number.isNaN(order.field_total_deposit)) {
      order.field_total_deposit = 0.0;
    }

    // var today = new Date();

    // let id =
    //   'RI-' +
    //   today.getFullYear() +
    //   '' +
    //   (today.getMonth() + 1) +
    //   '' +
    //   today.getDate() +
    //   '-' +
    //   cryptoRandomString({ length: 5, type: 'distinguishable' });

    // console.log('Order consclude : ' + id);
    // console.log(order);

    // the id should be same with the one in url
    const { id } = this.props.match.params;

    GoldMasterRestAPI.patch({
      service: "rental-invoice",
      data: {
        order: order,
      },
      id: id,
    })
      .then((data) => {
        this.setStatus("done");
        console.info(data);
        this.setState({ workingState: "done" });
      })
      .catch((e) => {
        //target.setLastError(e);
        console.log(e);
        this.setStatus("error");
        this.setState({ workingState: "error" });
      });
  };

  submitInput = (targetDom) => {};

  updateDiscount = (e) => {
    let flatDiscount = 0;

    flatDiscount = e.target.value;
    let total = this.getTotalAmount();

    total = total - flatDiscount;
    console.log("flat-disc");
    console.log(flatDiscount);

    this.setState({
      total: total,
      flatDiscount: flatDiscount,
      discount_flat: flatDiscount,
    });
  };

  getTotalAmount = () => {
    var alldata = this.getSubData();
    console.log("alldata");
    console.log(alldata);
    let total = alldata.reduce((p, c) => {
      //      console.log(c.subTotal);
      return p + (parseFloat(c.subTotal) ? parseFloat(c.subTotal) : 0.0);
    }, 0.0);

    return total;
  };

  childUpdate = (targetChild) => {
    let total = this.getTotalAmount();

    let flatDiscount = 0;

    flatDiscount = this.field_rentdiscount.current.value;

    total = total - flatDiscount;

    this.setState({ total: total, flatDiscount: flatDiscount });
    //this.setState({ total: total });
    //@TODO write to total
    var alldata = this.getSubData();

    let totalWeight = alldata.reduce((p, c) => {
      console.info(c);
      return p + (parseFloat(c.weight) ? parseFloat(c.weight) : 0.0);
    }, 0.0);

    this.setState({ totalWeight: totalWeight });

    let totalWeightTael = alldata.reduce((p, c) => {
      console.info("=========== C");
      console.info(c);
      return p + (parseFloat(c.weightTael) ? parseFloat(c.weightTael) : 0.0);
    }, 0.0);

    this.setState({ totalWeightTael: totalWeightTael });

    this.ensureEmptyRows();
  };

  getSubData = () => {
    let allCells = this.state.cells

      .map((cell) => cell.ref.current.getData())
      .filter((cell) => {
        console.log(cell);
        return cell.productIdValue;
      });

    this.setState({ allData: allCells });

    console.log(allCells);
    console.log("========");

    return allCells;
  };

  cellIdCount = 10;
  cells = [];

  updateState = () => {
    this.setState({ cells: this.state.cells });
  };

  updateTotalFromInput = (e) => {
    console.info("-------");
    console.info(this.override_total.current.value);
    console.info(e);
    this.setState({ total: this.override_total.current.value }); // updateTotalFromInput
  };

  formatMoney_one = (amount) => {
    return Number.parseFloat(amount).toFixed(1);
  };
  formatMoney = (amount) => {
    return Number.parseFloat(amount).toFixed(2);
  };

  formatWeight = (amount) => {
    return Number.parseFloat(amount).toFixed(3);
  };

  setStatus = (status) => {
    let icon = faCircle;
    let className = "";
    let color = "";
    let error = this.lastError ? this.lastError : {};
    switch (status) {
      case "init":
        icon = faCircle;

        break;
      case "ing":
        icon = faSync;
        className = "fa-spin";
        break;
      case "done":
        icon = faCheckCircle;
        color = "green";
        break;

      case "error":
        icon = faExclamationTriangle;
        color = "red";
        break;
      default:
        icon = faCircle;
        break;
    }
    this.setState({
      icon: icon,
      className: className,
      color: color,
      lastError: error,
    });
    console.info(error);
  };

  dateSelect = (e, field_name) => {
    console.log(e);
    console.log(field_name);
    var state = {};
    //var edate = moment(e).format("yyyy/MM/DD");
    state[field_name] = e;
    this.setState(state);
  };
  dateChange = (e, field_name) => {
    console.log(e);
    console.log(field_name);
    var state = {};
    //    var edate = moment(e).format("yyyy/MM/DD");
    state[field_name] = e;
    this.setState(state);
  };

  handleDeposit_discount = (e) => {
    this.setState({ deposit_discount: e.target.value });
  };
  handleDeposit_discount_percent = (e) => {
    this.setState({ deposit_discount_percent: e.target.value });
  };

  handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      this.setState({ valid: true });
      e.preventDefault();
      e.stopPropagation();
    } else {
      this.setState({ valid: true });
      this.submitToAPI();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  render() {
    let cellsTable =
      /*
        (
      <InventoryInputRow icon={faSync} submit={this.submitInput} />
        );
        */
      this.state.cells.map((cell) => {
        return (
          <>
            <OrderNoteItem
              icon={cell.icon}
              key={cell.key}
              mykey={cell.key}
              submit={this.submitInput}
              goldPrice={this.state._oldGoldPrice}
              updated={this.childUpdate}
              ref={cell.ref}
              defaultValues={{ ...cell.defaultValues }}
            />
          </>
        );
      });

    const renderStatus = (status) => {
      switch (status) {
        case "draft":
          return (
            <span>
              <FontAwesomeIcon icon={faPencilRuler} color="orange" />
              Draft
            </span>
          );
        case "ready_payment":
          return (
            <span>
              <FontAwesomeIcon icon={faMoneyBillAlt} color="green" />
              Ready to Accept Payment
            </span>
          );

        //paymentLink: data.link.authorize,
        //status: "ready_payment",
        default:
          return (
            <span>
              <FontAwesomeIcon icon={faExclamationTriangle} color="red" />
              {status}
            </span>
          );
      }
    };

    // Prod: https://drive.google.com/drive/folders/15WB-3vbcTXSnLkDHmAvyCh-M2c8J6HF3
    // UAT:  https://drive.google.com/drive/folders/1SfGhTea0EMD4rP0rTy_EnjE7IReOmM2O
    //
    return (
      <Form
        noValidate
        validated={this.state.valid}
        onSubmit={this.handleSubmit}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <h1>
                Edit Order Note &nbsp;
                <a href="https://drive.google.com/drive/folders/15WB-3vbcTXSnLkDHmAvyCh-M2c8J6HF3">
                  <FontAwesomeIcon icon={faGoogleDrive} />
                </a>
              </h1>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Container>
                <OrderNoteItem inputMode="true" />
              </Container>

              <Container>{cellsTable}</Container>
            </Col>
            {/* <!--  Lower Form - Left --> */}
            <Col xs={6}>
              <ClientField
                varName="clientNo"
                text="Client No."
                refID={this.field_clientno}
                value={this.state.clientNo}
                change={(e) => {
                  this.setState({ clientNo: e.target.value });
                }}
              />

              <ClientField
                varName="clientName"
                text="Client Name"
                refID={this.field_client}
                required
                value={this.state.clientName}
                change={(e) => {
                  this.setState({ clientName: e.target.value });
                }}
              />
              <ClientField
                varName="clientPhone"
                text="Mobile"
                refID={this.field_mobile}
                required
                value={this.state.clientPhone}
                change={(e) => {
                  this.setState({ clientPhone: e.target.value });
                }}
              />
              <ClientField
                varName="clientDetail"
                text="Details"
                fieldtype="textarea"
                rows={10}
                refID={this.field_details}
                value={this.state.clientDetail}
                change={(e) => {
                  this.setState({ clientDetail: e.target.value });
                }}
              />

              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="rentFrom">Rent From</InputGroup.Text>
                </InputGroup.Prepend>

                <DatePicker
                  ref={this.field_rentfrom}
                  value={this.state.field_rentfrom}
                  selected={this.state.field_rentfrom}
                  onSelect={(e) => this.dateSelect(e, "field_rentfrom")} //when day is clicked
                  onChange={(e) => this.dateChange(e, "field_rentfrom")} //only when value has changed
                  locale="en"
                  dateFormat="dd/MM/yyyy"
                />
              </InputGroup>

              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="rentTo">Rent To</InputGroup.Text>
                </InputGroup.Prepend>

                <DatePicker
                  selected={this.state.field_rentto}
                  ref={this.field_rentto}
                  onSelect={(e) => this.dateSelect(e, "field_rentto")} //when day is clicked
                  onChange={(e) => this.dateChange(e, "field_rentto")} //only when value has changed
                  value={this.state.field_rentto}
                  locale="en"
                  dateFormat="dd/MM/yyyy"
                />
              </InputGroup>

              <br />
            </Col>
            {/* <!--  Lower Form - right --> */}
            <Col xs={6}>
              <ClientField
                varName="invoiceno"
                text="Invoice No."
                refID={this.field_invoiceno}
                value={this.state.invoiceno}
                change={(e) => {
                  this.setState({ invoiceno: e.target.value });
                }}
              />
              <ClientField
                varName="iddoc"
                text="Identification Documents"
                refID={this.field_iddoc}
                value={this.state.iddoc}
                change={(e) => {
                  this.setState({ iddoc: e.target.value });
                }}
              />

              <ClientField
                varName="staffno"
                text="Staff No."
                refID={this.field_staffno}
                required
                value={this.state.staffno}
                change={(e) => {
                  this.setState({ staffno: e.target.value });
                }}
              />
              <ClientField
                varName="paymenttype"
                text="Payment Type"
                refID={this.field_paymenttype}
                value={this.state.paymenttype}
                change={(e) => {
                  this.setState({ paymenttype: e.target.value });
                }}
              />
              <ClientField
                varName="FlatDiscount"
                text="Flat Discount"
                refID={this.field_rentdiscount}
                change={this.updateDiscount}
                money
                value={this.state.discount_flat}
                //                change={(e) => {
                //                  this.setState({ discount_flat: e.target.value });
                //                }}
              ></ClientField>
              <ClientField
                varName="total"
                text="Total"
                refID={this.field_total}
                value={this.formatMoney(this.state.total)}
                change={(e) => {
                  this.setState({ field_total: e.target.value });
                }}
                money={true}
              />

              <br />

              <Container>
                <Row>
                  <Col xs={12}>
                    <h4>Deposit</h4>
                    <ClientField
                      varName="goldprice (Tael)"
                      text="Gold Price (Tael)"
                      refID={this.field_goldprice}
                      value={this.state.goldPricePerTael}
                      change={(e) => {
                        this.setState({ goldPricePerTael: e.target.value });
                      }}
                      readonly
                    />
                  </Col>
                  <Col xs={6}>
                    <ClientField
                      varName="Total Weight"
                      text="Total Weight"
                      refID={this.field_totalweight}
                      value={this.state.totalWeight}
                      change={(e) => {
                        this.setState({ totalWeight: e.target.value });
                      }}
                      readonly
                    />
                  </Col>
                  <Col xs={6}>
                    <ClientField
                      varName="Total Weight"
                      text="Total Weight (Tael)"
                      refID={this.field_totalweight_tael}
                      value={this.formatWeight(this.state.totalWeightTael)}
                      change={(e) => {
                        this.setState({ totalWeightTael: e.target.value });
                      }}
                      readonly
                    />
                  </Col>
                  <Col xs={12}>
                    <ClientField
                      varName="Deposit Discount %"
                      text="Deposit Discount %"
                      refID={this.field_deposit_discount_percent}
                      value={this.state.deposit_discount_percent}
                      change={this.handleDeposit_discount_percent}
                    />

                    <ClientField
                      varName="Deposit Discount Flat"
                      text="Deposit Discount Flat"
                      refID={this.field_deposit_discount}
                      value={this.state.deposit_discount}
                      change={this.handleDeposit_discount}
                      money
                    />

                    <ClientField
                      varName="Deposit Total"
                      text="Deposit Total"
                      refID={this.field_deposit_total}
                      value={this.formatMoney_one(
                        (this.formatWeight(this.state.totalWeightTael) *
                          this.state.goldPricePerTael *
                          (100.0 - this.state.deposit_discount_percent)) /
                          100.0 -
                          this.state.deposit_discount
                      )}
                      change={(e) => {
                        this.setState({ deposit_total: e.target.value });
                      }}
                      readonly
                      money
                    />
                  </Col>
                </Row>
              </Container>

              {/* 
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="Manual Total">
                    Override Total
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <FormControl
                  placeholder=""
                  aria-label=""
                  aria-describedby=""
                  //value={this.state.total}

                  ref={this.override_total}
                  onChange={this.updateTotalFromInput}
                />
              </InputGroup>
*/}
            </Col>
          </Row>
          <Row>
            <Col xs={4}>Status: {renderStatus(this.state.status)}</Col>
            <Col xs={4}></Col>
            <Col xs={4}>
              <div></div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {this.state.workingState &&
              this.state.workingState === "running" ? (
                <FontAwesomeIcon icon={faSync} className="fa-spin" />
              ) : null}

              {this.state.workingState && this.state.workingState === "done" ? (
                <FontAwesomeIcon icon={faCheckCircle} />
              ) : null}

              <button className="btn btn-primary" type="submit">
                Save
              </button>
              <button className="btn btn-secondary" type="button">
                Cancel
              </button>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              {this.state.paymentLink ? (
                <Row>
                  <Col xs={12}>
                    <input value={this.state.paymentLink} readOnly />
                    <br />
                    <a href={this.state.paymentLink} target="_blank">
                      <QRCode value={this.state.paymentLink} />
                      <br />
                      Deposit Now
                    </a>
                  </Col>
                </Row>
              ) : null}{" "}
            </Col>
            <Col xs={4}></Col>
            <Col xs={4}></Col>
          </Row>
        </Container>
      </Form>
    );
  }
}

export default OrderNoteEdit;

class ClientField extends React.Component {
  props = {};
  constructor(props) {
    super(props);

    /*
    let { varName, text, refName, fieldtype, rows, onChange } = props;

    this.varName = varName;
    this.text = text;
    this.refName = refName;
    this.fieldtype = fieldtype;
    this.rows = rows;
    this.onChange = onChange;
    */

    this.props = props;
    this.state = {};
  }

  render() {
    return (
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text id={this.props.varName}>
            {this.props.text}
          </InputGroup.Text>
        </InputGroup.Prepend>

        {
          <FormControl
            placeholder=""
            aria-label={this.props.varName}
            aria-describedby={this.props.varName}
            ref={this.props.refID}
            as={this.props.fieldtype}
            rows={this.props.rows}
            onChange={
              this.props.readonly
                ? () => {}
                : this.props.change
                ? this.props.change
                : null
            }
            value={this.props.value}
            required={this.props.required}
            className={this.props.readonly ? "classReadOnly" : ""}
          />
        }

        {this.props.money ? (
          <NumberFormat
            value={this.props.value}
            thousandSeparator={true}
            prefix={"$"}
            displayType={"text"}
            style={{ float: "right", padding: "10px" }}
          />
        ) : null}
      </InputGroup>
    );
  }
}
