import React from "react";
import PropTypes from "prop-types";
import { Component } from "react";

import jquery from "jquery";
import $ from "jquery";
import Popper from "popper.js";
import { Link } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle.min";

import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faCircle,
  faSync,
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import Alert from "react-bootstrap/Alert";

const GoldMasterRestAPI = require("../../service/GoldMasterRestAPI.js").default;

export class ProductInputRow extends Component {
  constructor({ props, icon, mykey, submit }) {
    super(props);
    this.state = {
      Metal: "AU999.9",
      ProductID: "",
      ProductName: "",
      desc: "",
      weight: "",
      Vendor: "",
      valid: false,
    };
    this.state.icon = faSync;
    this.className = "";
    this.key = mykey;
    this.submitCall = submit;

    this.inventoryID = React.createRef();
    this.weight = React.createRef();
    this.rentalCost = React.createRef();

    this.weighttael = React.createRef();
    this.lengthcm = React.createRef();
    this.width = React.createRef();
    //this.setState({ icon: icon });
  }

  formatWeight = (amount) => {
    return Number.parseFloat(amount).toFixed(3);
  };

  getData = () => {
    var data = {};

    data.ProductID = this.state.ProductID;
    data.ProductName = this.state.ProductName;
    data.Metal = this.state.Metal;
    data.desc = this.state.desc;
    data.weight = this.state.weight;
    data.Vendor = this.state.Vendor;

    data.rentalCost = this.state.rentalCost;

    data.weighttael = this.state.weighttael;
    data.width = this.state.width;
    data.lengthcm = this.state.lengthcm;

    data.ordered = 0;
    data.inventoryCount = 0;
    data.inventoryinventoryRented = 0;

    return data;
  };
  submit = () => {
    this.submitCall(this);
  };

  handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      this.setState({ valid: true });
      e.preventDefault();
      e.stopPropagation();
    } else {
      this.setState({ valid: true });
      this.submitCall(this);
      e.preventDefault();
      e.stopPropagation();
    }
  };
  setStatus = (status) => {
    let icon = faCircle;
    let className = "";
    let color = "";
    let error = this.lastError ? this.lastError : {};
    switch (status) {
      case "init":
        icon = faCircle;
        break;
      case "ing":
        icon = faSync;
        className = "fa-spin";
        break;
      case "done":
        icon = faCheckCircle;
        color = "green";
        break;

      case "error":
        icon = faExclamationTriangle;
        color = "red";
        break;
      default:
        icon = faCircle;
        break;
    }
    this.setState({
      icon: icon,
      className: className,
      color: color,
      lastError: error,
    });
    console.info(error);
  };

  setLastError = (error) => {
    this.lastError = error;
  };

  render = () => (
    <div>
      <Form
        noValidate
        validated={ this.state.valid }
        onSubmit={ this.handleSubmit }
      >
        <Row>
          <Col xs={ 2 }>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="ProductID">ProductID</InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
            <InputGroup hasValidation>
              <FormControl
                placeholder="ProductID"
                aria-label="ProductID"
                aria-describedby="ProductID"
                required
                ref={ this.ProductID }
                value={ this.state.ProductID }
                onChange={ (e) => this.setState({ ProductID: e.target.value }) }
              />
            </InputGroup>
          </Col>

          <Col xs={ 2 }>
            <InputGroup>
              <FormControl
                placeholder="ProductName"
                aria-label="ProductName"
                aria-describedby="ProductName"
                ref={ this.ProductName }
                value={ this.state.ProductName }
                onChange={ (e) => this.setState({ ProductName: e.target.value }) }
              />
            </InputGroup>
            <InputGroup>
              <FormControl
                placeholder="Metal"
                aria-label="Metal"
                aria-describedby="Metal"
                ref={ this.Metal }
                value={ this.state.Metal }
                onChange={ (e) => this.setState({ Metal: e.target.value }) }
              />
            </InputGroup>
          </Col>

          <Col xs={ 2 }>
            <InputGroup>
              <FormControl
                placeholder="Description"
                aria-label="Weight"
                aria-describedby="basic-addon2"
                as="textarea"
                rows={ 3 }
                ref={ this.desc }
                value={ this.state.desc }
                onChange={ (e) => this.setState({ desc: e.target.value }) }
              />
            </InputGroup>
          </Col>

          <Col xs={ 2 }>
            <InputGroup>
              <FormControl
                placeholder="Reference Weight"
                aria-label="Reference Weight"
                aria-describedby="basic-addon2"
                required
                ref={ this.weight }
                value={ this.state.weight }
                onChange={ (e) => {
                  let weighttael = this.formatWeight(e.target.value / 37.429);
                  this.setState({
                    weight: e.target.value,
                    weighttael: weighttael,
                  });
                } }
              />
              <InputGroup.Append>
                <InputGroup.Text id="basic-addon2">Gram</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            <InputGroup>
              <FormControl
                placeholder="Weight (Tael)"
                aria-label="weighttael"
                aria-describedby="weighttael"
                ref={ this.weighttael }
                value={ this.state.weighttael }
                onChange={ (e) => {
                  let weight = this.formatWeight(e.target.value * 37.429);
                  this.setState({
                    weight: weight,
                    weighttael: e.target.value,
                  });

                  this.setState({ weighttael: e.target.value });
                } }
              />

              <InputGroup.Append>
                <InputGroup.Text id="basic-addon2">Tael</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>

          <Col xs={ 2 }>
            <InputGroup.Prepend>
              <InputGroup.Text id="rentalCost">Rental Cost</InputGroup.Text>
            </InputGroup.Prepend>
            <InputGroup>
              <FormControl
                placeholder="Rental Cost"
                aria-label="rentalCost"
                aria-describedby="rentalCost"
                ref={ this.rentalCost }
                value={ this.state.rentalCost }
                required
                onChange={ (e) => {
                  this.setState({
                    rentalCost: e.target.value,
                  });
                } }
              />
            </InputGroup>
          </Col>
          <Col xs={ 1 }>
            <FontAwesomeIcon
              icon={ this.state.icon }
              className={ this.state.className }
              color={ this.state.color }
            />
          </Col>
        </Row>
        <Row>
          <Col xs={ 2 }>
            <InputGroup>
              <FormControl
                placeholder="Vendor"
                aria-label="Vendor"
                aria-describedby="vendor"
                ref={ this.Vendor }
                value={ this.state.Vendor }
                onChange={ (e) => this.setState({ Vendor: e.target.value }) }
              />
            </InputGroup>
          </Col>
          <Col xs={ 2 }>
            <FormControl
              placeholder="Width (cm)"
              aria-label="width"
              aria-describedby="width"
              ref={ this.width }
              value={ this.state.width }
              onChange={ (e) => this.setState({ width: e.target.value }) }
            />
          </Col>
          <Col xs={ 2 }>
            <FormControl
              placeholder="Length (cm)"
              aria-label="lengthcm"
              aria-describedby="lengthcm"
              ref={ this.lengthcm }
              value={ this.state.lengthcm }
              onChange={ (e) => this.setState({ lengthcm: e.target.value }) }
            />
          </Col>

          <Col xs={ 6 }>
            <button type="submit">Submit</button>

            <FontAwesomeIcon
              icon={ this.state.icon }
              className={ this.state.className }
              color={ this.state.color }
            />
          </Col>

          { this.state.lastError &&
            this.state.lastError !== {} &&
            this.state.lastError.message && (
              <Col xs={ 12 }>
                <Alert variant="danger">{ this.state.lastError?.message }</Alert>
              </Col>
            ) }

          <Col xs={ 6 }>
            <Link to="/ProductList">
              <button style={ { float: "left", marginTop: "20px" } } type="button">
                Back
              </button>
            </Link>
          </Col>
        </Row>
      </Form>
    </div>
  );

  /*
              <button
              className="btn btn-primary"
              type="button"
              onClick={this.submit}
            >
              Sync
            </button>

   */
}

export class ProductInput extends Component {
  submitToAPI = (payload) => {
    let { target, ProductID } = payload;

    //try to extract the code and put it into productID

    console.log("====== Payload =====");
    console.log(payload);
    payload.target = null;
    GoldMasterRestAPI.create({
      service: "product",
      data: {
        _id: ProductID,
        status: "ready",
        ...payload,
      },
    })
      .then((data) => {
        target.setStatus("done");
      })
      .catch((e) => {
        target.setLastError(e);
        target.setStatus("error");
      });
  };

  submitInput = (targetDom) => {
    this.cellIdCount++;

    //console.info(hi);
    //hi.setIcon(faCoffee);

    this.cells.forEach((elm, index) => {
      if (elm.key === targetDom.key) {
        this.cells[index].icon = faCoffee;
      }
    });

    targetDom.setStatus("ing");
    var data = targetDom.getData();

    //target: targetDom,
    this.submitToAPI({
      target: targetDom,
      ...data,
    });

    //create new cell
    this.cells.push({ key: this.cellIdCount, icon: faCircle });
    //update view
    this.updateState();
  };

  cellIdCount = 0;
  cells = [];

  constructor() {
    super();
    this.cells = [{ key: 0, icon: faCircle }];
    this.state = { cells: this.cells };
  }

  updateState = () => {
    this.setState({ cells: this.cells });
  };

  render() {
    let cellsTable = this.state.cells.map((cell) => {
      return (
        <ProductInputRow
          icon={ cell.icon }
          key={ cell.key }
          mykey={ cell.key }
          submit={ this.submitInput }
        />
      );
    });

    return (
      <Form>
        <Container>{ cellsTable }</Container>
      </Form>
    );
  }
}

export default ProductInput;
