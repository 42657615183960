import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import GoldMasterRestAPI from "../../service/GoldMasterRestAPI";
import { useSelector, useDispatch } from "react-redux";

import orderListAction from "../../actions/ordersListAction";

import {
  Modal,
  Button,
  Tooltip,
  OverlayTrigger,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import {
  faQrcode,
  faCashRegister,
  faExclamationTriangle,
  faUnlock,
  faSync,
} from "@fortawesome/free-solid-svg-icons";

var QRCode = require("qrcode.react");

export class OrderListActionButtonBar extends Component {
  dispatch = null;
  constructor(props) {
    super(props);
    this.data = props.data;
    this.index = props.i;
    this.parent = props.parent;
    this.state = {};
    //this.dispatch = useDispatch();
    this.dispatch = (data) => { }; // redux later
  }

  action(act, data) {
    let action = "";
    switch (act) {
      case "void":
        action = "void";
        break;
      case "capture":
        action = "capture";
        break;
      case "reauth":
        action = "reauth";
        break;

      default:
    }

    this.dispatch(
      orderListAction({
        source: "button",
        action: action,
        data: { action: action, id: data.id, service: "orders" },
      })
    );
    return GoldMasterRestAPI.patch({
      service: "orders",
      id: data.id,
      data: { action: action },
    })
      .then((data) => {
        console.log("Done");
        console.info(data);
        this.setState({
          show_modal_void: false,
          show_modal_capture: false,
          show_modal_reauth: false,
        });
        this.parent.handlePageChange(-1);
      })
      .catch((err) => {
        console.log("Er er");
        console.log(err);
      });
  }

  render() {
    //return <div></div>;
    let output = <div></div>;
    let status = this.data.status;

    console.info(this.data);

    switch (status) {
      case "order-links-created":
        output = this.createQR(this.data);
        break;
      case "auth_acquired":
        output = this.createDepositAction(this.data);
        break;

      default:
    }

    return output;
  }

  createDepositAction(data) {
    let handleVoid = () => {
      this.action("void", this.data);
    };

    let handleCapture = () => {
      this.action("capture", this.data);
    };

    let handleReauth = () => {
      this.action("reauth", this.data);
    };
    let handleCaptureModal = () => this.setState({ show_modal_capture: true });
    let handleVoidModal = () => this.setState({ show_modal_void: true });
    let handleReauthModal = () => this.setState({ show_modal_reauth: true });

    let handleClose = () => {
      this.setState({
        show_modal_void: false,
        show_modal_capture: false,
        show_modal_reauth: false,
      });
    };

    let output = (
      <div>
        <OverlayTrigger
          key="top"
          placement="top"
          overlay={ <Tooltip id={ `tooltip-top` }>Capture</Tooltip> }
        >
          <Button
            variant="outline-danger"
            onClick={ handleCaptureModal }
            size="sm"
          >
            <FontAwesomeIcon icon={ faCashRegister } color="red" />
          </Button>
        </OverlayTrigger>
        <Modal
          show={ this.state.show_modal_capture }
          onHide={ handleClose }
          animation={ false }
          centered={ true }
        >
          <Modal.Header>
            You are about the CAPTURE client's fund.
            <br />
            Click Close if you are not sure.
          </Modal.Header>
          <Modal.Body>
            <center>
              <FontAwesomeIcon
                icon={ faExclamationTriangle }
                color="red"
                size="6x"
              />
              <h1>Capture</h1>
            </center>
          </Modal.Body>
          <Modal.Footer>
            <Container>
              <Row>
                <Col className="float-left">
                  <Button variant="danger" onClick={ handleCapture }>
                    Capture
                  </Button>
                </Col>
                <Col
                  className="float-right pull-right"
                  style={ { "text-align": "right" } }
                >
                  <Button variant="success" onClick={ handleClose }>
                    Close
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Footer>
        </Modal>
        &nbsp;
        <OverlayTrigger
          key="top"
          placement="top"
          overlay={ <Tooltip id={ `tooltip-top` }>Void</Tooltip> }
        >
          <Button variant="outline-danger" onClick={ handleVoidModal } size="sm">
            <FontAwesomeIcon icon={ faUnlock } color="red" />
          </Button>
        </OverlayTrigger>
        <Modal
          show={ this.state.show_modal_void }
          onHide={ handleClose }
          animation={ false }
          centered={ true }
        >
          <Modal.Header>
            You are about the VOID the deposit Lock
            <br />
            Click Close if you are not sure.
          </Modal.Header>
          <Modal.Body>
            <center>
              <FontAwesomeIcon icon={ faUnlock } color="red" size="6x" />
              <h1>Release</h1>
            </center>
          </Modal.Body>
          <Modal.Footer>
            <Container>
              <Row>
                <Col className="float-left">
                  <Button variant="danger" onClick={ handleVoid }>
                    Void
                  </Button>
                </Col>
                <Col
                  className="float-right pull-right"
                  style={ { "text-align": "right" } }
                >
                  <Button variant="success" onClick={ handleClose }>
                    Close
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Footer>
        </Modal>
        &nbsp;
        <OverlayTrigger
          key="top"
          placement="top"
          overlay={ <Tooltip id={ `tooltip-top` }>ReAuth</Tooltip> }
        >
          <Button
            variant="outline-primary"
            onClick={ handleReauthModal }
            size="sm"
          >
            <FontAwesomeIcon icon={ faSync } color="blue" />
          </Button>
        </OverlayTrigger>
        <Modal
          show={ this.state.show_modal_reauth }
          onHide={ handleClose }
          animation={ false }
          centered={ true }
        >
          <Modal.Header>
            You are about the Reauth
            <br />
            Note that, reauth before 3 days auth will result in fail
          </Modal.Header>
          <Modal.Body>
            <center>
              <FontAwesomeIcon icon={ faSync } color="green" size="6x" />
              <h1>ReAuth</h1>
            </center>
          </Modal.Body>
          <Modal.Footer>
            <Container>
              <Row>
                <Col className="float-left">
                  <Button variant="danger" onClick={ handleReauth }>
                    ReAuth
                  </Button>
                </Col>
                <Col
                  className="float-right pull-right"
                  style={ { "text-align": "right" } }
                >
                  <Button variant="success" onClick={ handleClose }>
                    Close
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Footer>
        </Modal>
      </div>
    );

    return output;
  }
  createQR(data) {
    let handleOpen = () => {
      this.setState({ modal_open: true });
    };
    let handleClose = () => {
      this.setState({ modal_open: false });
    };

    let qrlink = GoldMasterRestAPI.apiURL + "/payDeposit/" + data.id;

    let output = (
      <div>
        <OverlayTrigger
          key="top"
          placement="top"
          overlay={
            <Tooltip>Share the following link to Client to do payment</Tooltip>
          }
        >
          <Button variant="outline-primary" onClick={ handleOpen } size="sm">
            <FontAwesomeIcon icon={ faQrcode } color="blue" />
          </Button>
        </OverlayTrigger>

        <Modal
          show={ this.state.modal_open }
          onHide={ handleClose }
          animation={ false }
        >
          <Modal.Header>
            Share the following link to Client to do payment
          </Modal.Header>
          <Modal.Body>
            <center>
              <QRCode value={ qrlink } />
              <br />
              <input value={ qrlink } readOnly />
            </center>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={ handleClose }>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );

    return output;
  }
}
export default OrderListActionButtonBar;
//export default orderListAction;

function ModularButton({
  handleVoidModal,
  faUnlock,
  handleClose,
  handleVoid,
  show_modal_void,
}) {
  return (
    <>
      { " " }
      <OverlayTrigger
        key="top"
        placement="top"
        overlay={ <Tooltip id={ `tooltip-top` }>Void</Tooltip> }
      >
        <Button variant="outline-danger" onClick={ handleVoidModal } size="sm">
          <FontAwesomeIcon icon={ faUnlock } color="red" />
        </Button>
      </OverlayTrigger>
      <Modal
        show={ show_modal_void }
        onHide={ handleClose }
        animation={ false }
        centered={ true }
      >
        <Modal.Header>
          You are about the VOID the deposit Lock
          <br />
          Click Close if you are not sure.
        </Modal.Header>
        <Modal.Body>
          <center>
            <FontAwesomeIcon icon={ faUnlock } color="red" size="6x" />
            <h1>Release</h1>
          </center>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col className="float-left">
                <Button variant="danger" onClick={ handleVoid }>
                  Void
                </Button>
              </Col>
              <Col
                className="float-right pull-right"
                style={ {
                  "text-align": "right",
                } }
              >
                <Button variant="success" onClick={ handleClose }>
                  Close
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
}
