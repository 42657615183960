const feathers = require("@feathersjs/feathers");
const rest = require("@feathersjs/rest-client");

const app = feathers();
// Connect to a different URL
let restClient = null;

let apiURL = "";
if (process.env.NODE_ENV === "production") {
  apiURL = "https://api2.k-gold.shop";
//  if (process.env.NODE_ENV_API && process.env.NODE_ENV_API != "") {
//    apiURL = process.env.NODE_ENV_API;
//  }
} else {
  apiURL = "http://localhost:3040";
}

restClient = rest(apiURL);

const auth = require("@feathersjs/authentication-client");

const axios = require("axios");

//app.configure(restClient.axios(axios)); //https://docs.feathersjs.com/api/client/rest.html#feathersjs-rest-client

class GoldMasterRestAPI {
  static instance;

  constructor() {
    if (!GoldMasterRestAPI.instance) {
      this._data = [];

      app.configure(
        auth({
          storageKey: "auth",
        })
      );

      this.apiURL = apiURL;

      app.configure(restClient.axios(axios));

      this.app = app;
      GoldMasterRestAPI.instance = this;
    }

    return GoldMasterRestAPI.instance;
  }

  login = async (loginInfo) => {
    const { email, password } = loginInfo || {};
    try {
      return await this.app.reAuthenticate();
    } catch (err) {
      return this.app
        .authenticate({
          strategy: "local",
          email,
          password,
        })
        .then((d) => {
          console.info(d);
          return d;
        })
        .catch((e) => {
          console.info(e);
          window.location.href = "/login?expired=true";
          return e;
        });
    }
  };

  logout = async () => {
    try {
      return await this.app.logout();
    } catch (e) {
      console.log(e);
    }
  };

  getData = (query) => {
    let { perPage, page, service, query_parameter, sort } = query;

    query_parameter = query_parameter ? query_parameter : {};
    perPage = perPage ? perPage : 50000;
    page = page ? page : 1;

    let skip = (page - 1) * perPage;
    sort = sort ? sort : {};

    // console.log("===========");
    // console.log({
    //   query: { $limit: perPage, $skip: skip, ...query_parameter },
    // });
    return this.login().then(() => {
      return GoldMasterRestAPI.instance.app.service(service).find({
        query: {
          $limit: perPage,
          $skip: skip,
          ...query_parameter,
          $sort: sort,
        },
      });
      //`https://api.k-gold.shop/price?$limit=${perPage}&$skip=${skip}`
    });
  };

  getDataByID = ({ id, service }) => {
    return this.login().then(() => {
      return GoldMasterRestAPI.instance.app.service(service).get(id);
    });
  };

  create = (payload) => {
    let { data, service } = payload;
    return this.login().then((done) => {
      return GoldMasterRestAPI.instance.app.service(service).create(data);
    });
  };

  patch = (payload) => {
    let { data, service, id } = payload;
    return this.login().then((done) => {
      return GoldMasterRestAPI.instance.app.service(service).patch(id, data);
    });
  };

  remove = (payload) => {
    let { data, service, id } = payload;
    return this.login().then((done) => {
      return GoldMasterRestAPI.instance.app.service(service).remove(id);
    });
  };

  getGoldPrice = () => {
    return this.login().then(() => {
      console.info(axios);
      console.info(GoldMasterRestAPI.instance.app.service("price").get(0));

      return GoldMasterRestAPI.instance.app.service("price").get(0);
      //`https://api.k-gold.shop/price?$limit=${perPage}&$skip=${skip}`
    });
  };

  getVersion = () => {
    return axios.get(apiURL + "/version");
  };

  formatMoney_one = (amount) => {
    return Number.parseFloat(amount).toFixed(2);
  };

  formatMoney = (amount) => {
    return Number.parseFloat(amount).toFixed(2);
  };

  formatWeight = (amount) => {
    return Number.parseFloat(amount).toFixed(3);
  };

  hi = () => {
    return "Hihihi";
  };
}

export default GoldMasterRestAPI = new GoldMasterRestAPI();
