import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PriceList from '../scene/PriceList.js';
import InventoryInput from '../scene/inventoryInput';
import OrderInput from '../scene/RentalNote/RentalNoteInput';
import RentalNoteEdit from '../scene/RentalNote/RentalNoteEdit';
import SaleNote from '../scene/SaleNote/SaleNote';
import SaleNoteList from '../scene/SaleNote/SaleNoteList';
import SalesNoteEdit from '../scene/SaleNote/SalesNoteEdit';

import RentalNoteList from '../scene/orderList/orderList';

import InventoryList from '../scene/InventoryList';
import ProductInput from '../scene/Product/ProductInput';
import ProductList from '../scene/Product/ProductList';
import OrderNote from '../scene/OrderNote/OrderNote';
import OrderNoteList from '../scene/OrderNote/OrderNoteList';
import OrderNoteEdit from '../scene/OrderNote/OrderNoteEdit';

import InventoryCheckio from '../scene/InventoryCheckio/InventoryCheckio';

import Home from '../scene/Home.js';
import OrderList from '../scene/orderList/orderList';
import { Helmet } from 'react-helmet';
import { Component } from 'react';
import Login from '../scene/login';
import InventoryEdit from '../scene/InventoryEdit';
import ProductEdit from '../scene/Product/ProductEdit';

import { useLocation } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let pack = require('../../package.json');
const GoldMasterRestAPI = require('../service/GoldMasterRestAPI.js').default;

const Header = () => {
  const location = useLocation();
  const [api_version, setAPIVersion] = React.useState('?');
  const [user_email, setUserEmail] = React.useState('');
  React.useEffect(async () => {
    const { data } = await GoldMasterRestAPI.getVersion();
    console.info('----');
    console.info(data);
    // this.setState({ api_version: data });
    setAPIVersion(data);
  }, []);
  React.useEffect(async () => {
    console.log(window.location.href);
    if (window.location.href.indexOf('/login') == -1) {
      GoldMasterRestAPI.login().then((r) => {
        const {
          user: { email },
        } = r;
        // this.setState({ user_email: email });
        setUserEmail(email);
      });
    }
  }, [location]);

  // constructor(props) {
  //   super(props);
  //   // this.state = {
  //   //   api_version: "?",
  //   //   user_email: ""
  //   // };

  //   // GoldMasterRestAPI.getVersion().then((data) => {
  //   //   console.info("----");
  //   //   console.info(data.data);
  //   //   this.setState({ api_version: data.data });
  //   // });
  // }

  // componentDidMount() {
  //   this.timerID = setInterval(
  //     () => this.tick(),
  //     1000
  //   );
  // }
  // componentWillUnmount() {
  //   clearInterval(this.timerID);
  // }
  // tick() {
  //   if (window.location.href.indexOf("/login") == -1) {
  //     GoldMasterRestAPI.login().then(r => {
  //       const { user: { email } } = r;
  //       this.setState({ user_email: email });
  //     })
  //   }
  // }

  const handleLogout = async () => {
    await GoldMasterRestAPI.logout();
    // localStorage.getItem("auth");
    window.location.href = '/login?logout=true';
  };

  // render() {
  return (
    <div>
      <Helmet>
        <title>Kallisti - Service Platform</title>
        <link
          rel='icon'
          href='https://www.k-gold.shop/wp-content/uploads/2020/09/Kalisti_favicon.svg'
          type='image/png'
        />
      </Helmet>
      <BrowserRouter>
        <Navbar
          sticky='top'
          collapseOnSelect
          expand='lg'
          bg='dark'
          variant='dark'
          width='100%'>
          <Navbar.Brand href='/'>
            {' '}
            <img
              alt='K-shop Logo'
              width='120px'
              src='https://www.k-gold.shop/wp-content/uploads/2020/08/Kallisti_Menu_Logo_240_85.svg'
            />{' '}
            <span>Gold-Master</span>{' '}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='responsive-nav-bar-nav' />
          <Navbar.Collapse id='responsive-nav-bar-nav'>
            <Nav className='mr-auto'>
              <NavDropdown title='Records'>
                <NavDropdown.Header>Records:</NavDropdown.Header>
                <NavDropdown.Item href='/Price'>Price</NavDropdown.Item>
                <NavDropdown.Item href='/ProductList'>
                  Product List
                </NavDropdown.Item>
                <NavDropdown.Item href='/Inventory'>
                  Inventory List
                </NavDropdown.Item>
                <NavDropdown.Item href='/OrderNoteList'>
                  Order Note List
                </NavDropdown.Item>
                <NavDropdown.Item href='/RentalNoteList'>
                  Rental Note List
                </NavDropdown.Item>
                <NavDropdown.Item href='/InventoryCheckio'>
                  InventoryCheckio
                </NavDropdown.Item>
                <NavDropdown.Item href='/SaleNoteList'>
                  Sale Note List
                </NavDropdown.Item>

                <NavDropdown.Divider />
              </NavDropdown>
              <NavDropdown title='Actions'>
                <NavDropdown.Header>Actions:</NavDropdown.Header>

                <NavDropdown.Item href='/ProductInput'>
                  Product Input
                </NavDropdown.Item>

                <NavDropdown.Item href='/InventoryInput'>
                  Inventory Input
                </NavDropdown.Item>

                <NavDropdown.Item href='/OrderNote'>
                  Create Order Note
                </NavDropdown.Item>

                <NavDropdown.Item href='/RentalNote'>
                  Create Rental Note
                </NavDropdown.Item>

                <NavDropdown.Item href='/SaleNote'>
                  Create Sale Note
                </NavDropdown.Item>

                <NavDropdown.Item href='/DepositReturn' disabled>
                  Return Deposit
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title='Users'>
                {localStorage.getItem('auth') && (
                  <NavDropdown.Item onClick={() => handleLogout()}>
                    Logout
                  </NavDropdown.Item>
                )}
                {!localStorage.getItem('auth') && (
                  <NavDropdown.Item href='/login'>Login</NavDropdown.Item>
                )}
              </NavDropdown>
            </Nav>
            <Navbar.Brand className='float-right' inline='true'>
              <small>{user_email}</small>
            </Navbar.Brand>
            <Navbar.Brand className='float-right' inline='true'>
              <small>
                <font color='white'>
                  Api-{api_version} | {pack.name} - {pack.version} -
                  {process.env.REACT_APP_BUILD_TIME}
                </font>
              </small>
            </Navbar.Brand>
          </Navbar.Collapse>
        </Navbar>

        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/Price' component={PriceList} />

          <Route path='/ProductInput' component={ProductInput} />
          <Route path='/ProductList' component={ProductList} />
          <Route path='/ProductEdit/:id' component={ProductEdit} />
          <Route path='/OrderNote' component={OrderNote} />
          <Route path='/OrderNoteList' component={OrderNoteList} />
          <Route path='/OrderNoteEdit/:id' component={OrderNoteEdit} />

          <Route path='/SaleNote' component={SaleNote} />

          <Route path='/InventoryInput' component={InventoryInput} />
          <Route path='/Inventory' component={InventoryList} />
          <Route path='/InventoryEdit/:id' component={InventoryEdit} />

          <Route path='/login' component={Login} />

          <Route path='/RentalNote' component={OrderInput} />
          <Route path='/RentalNoteList' component={RentalNoteList} />
          <Route path='/RentalNoteEdit/:id' component={RentalNoteEdit} />
          <Route path='/InventoryCheckio' component={InventoryCheckio} />
          <Route path='/SaleNoteList' component={SaleNoteList} />
          <Route path='/SaleNoteEdit/:id' component={SalesNoteEdit} />
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
  // }
};

export default Header;
