import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Form,
  Button,
  InputGroup,
  FormControl,
  Container,
  Col,
  Row,
  Alert,
} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as qs from 'qs';
import { useHistory } from 'react-router-dom';

const GoldMasterRestAPI = require('../service/GoldMasterRestAPI.js').default;

const Login = () => {
  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    setDisableButton(true);

    const { accessToken, message } = await GoldMasterRestAPI.login(data);
    if (accessToken) {
      // setToast({ display: true, message: 'Login success', variant: 'success' });
      toast('Login successfully', { type: 'success' });
      setDisableButton(false);
      window.location.pathname = '/Price';
    } else {
      // setToast({ display: true, message, variant: 'danger' });
      toast(message, { type: 'error' });
    }
  };

  const [disableButton, setDisableButton] = React.useState(false);

  React.useEffect(() => {
    const { expired, logout } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    if (expired) {
      toast('Login Status Expired, please login.', { type: 'error' });
      return;
    }
    if (logout) {
      toast('Logout successfully', { type: 'success' });
      return;
    }
  }, [window.location.search]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Row>
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id={'email_text'}>E-mail</InputGroup.Text>
                <FormControl
                  placeholder='Email'
                  aria-label='email_text'
                  aria-describedby='email_text'
                  {...register('email', { required: true })}
                  as='input'
                  type='email'
                />
              </InputGroup.Prepend>
            </InputGroup>
          </Col>
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id={'password_text'}>Password</InputGroup.Text>
                <FormControl
                  placeholder='Password'
                  aria-label='password_text'
                  aria-describedby='password_text'
                  {...register('password', { required: true })}
                  as='input'
                  type='password'
                />
              </InputGroup.Prepend>
            </InputGroup>
          </Col>
          <Col>
            <Button variant='primary' type='submit' disabled={disableButton}>
              Log in
            </Button>
          </Col>
        </Row>
      </Container>
      <ToastContainer></ToastContainer>
    </Form>
  );
};
export default Login;
