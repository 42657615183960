import React, { Component } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import moment from "moment-timezone";

import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserClock,
  faStore,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

const GoldMasterRestAPI = require("../service/GoldMasterRestAPI.js").default;

const columns = [
  {
    name: "Client No.",
    selector: "field_clientno",
    sortable: true,
    right: true,
    data: "field_clientno",
    // format: (row, index) => {
    //   return moment(row.createdAt).tz("Asia/Hong_Kong").format("lll");
    // },
  },
  {
    name: "Client Name",
    selector: "field_client",
    sortable: true,
    right: true,
    data: "field_client",
    // format: (row, index) => {
    //   return moment(row.createdAt).tz("Asia/Hong_Kong").format("lll");
    // },
  },
  {
    name: "Mobile",
    selector: "field_mobile",
    sortable: true,
    right: true,
    data: "field_mobile",
    // format: (row, index) => {
    //   return moment(row.createdAt).tz("Asia/Hong_Kong").format("lll");
    // },
  },
  {
    name: "Create Date",
    selector: "createdAt",
    sortable: true,
    right: true,
    data: "createdAt",
    format: (row, index) => {
      return moment(row.createdAt).tz("Asia/Hong_Kong").format("lll");
    },
  },
  {
    name: "Create By",
    selector: "createdBy",
    sortable: true,
    format: (row, index) => {
      return row.createdBy?.email || 'N/A';
    },
  },
  {
    name: "Update Date",
    selector: "updateAt",
    data: "updateAt",
    sortable: true,
    right: true,
    format: (row, index) => {
      return moment(row.createdAt).tz("Asia/Hong_Kong").format("lll");
    },
  },
  {
    name: "Update By",
    selector: "updateBy",
    sortable: true,
    format: (row, index) => {
      return row.updateBy?.email || 'N/A';
    },
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
    right: true,

    format: (row, index) => {
      let icon = faUserClock;
      let color = "";

      switch (row.status) {
        case "checkout":
          color = "";
          icon = faUserClock;
          break;
        case "ready":
          icon = faStore;
          color = "green";
          break;
        case "return-to-vendor":
          icon = faStore;
          color = "yellow";
          break;
        default:
          icon = faQuestionCircle;
          color = "orange";
      }

      return (
        <span>
          { row.status }
          &nbsp;
          <FontAwesomeIcon icon={ icon } color={ color } />
        </span>
      );
    },
  },
];

createTheme("solarized", {
  text: {
    primary: "rgba(22, 0, 0, 0.87)",
    secondary: "rgba(22, 0, 0, 0.54)",
    disabled: "rgba(33, 0, 0, 0.38)",
  },
  background: {
    default: "#DDDDDD",
  },
});

export class InvHist extends Component {
  constructor(props) {
    super(props);
    console.log(">>>>>>>");
    console.log(props);
    this.inventoryID = props.data._id;
    this.props = props;
    this.state = { data: [], productData: props };
  }

  componentDidMount() {
    this.getInventoryData(this.inventoryID);
  }

  // 7:
  // createdAt: "2020-11-12T08:21:52.756Z"
  // productID: "nl009"
  // status: "ready"
  // updateAt: "2020-11-12T08:21:52.756Z"
  // weight: "40"
  // _id: "nl009-004"
  // __proto__: Object

  getInventoryData = (inventoryID) => {
    // getData = (query) => {
    //   let { perPage, page, service, query_parameter } = query;
    console.log("====== InvData Grep" + inventoryID);

    GoldMasterRestAPI.getData({
      perPage: 40,
      page: 0,
      service: "inv-history",
      query_parameter: {
        inventoryID: inventoryID,
      },
      sort: {
        createdAt: -1,
      },
    })
      .then((resp) => {
        console.log("Data:");
        console.log(resp);
        this.setState({ data: resp.data });
      })
      .catch((e) => {
        console.log("Error:");
        console.log(e);
      });
  };

  render() {
    var row = {};
    //this.state.data.;

    console.log(this.props);
    var inventoryRented = this.state.productData?.inventoryRented
      ? this.state.productData.inventoryRented
      : 0;
    var inventoryCount = this.state.productData?.inventoryCount
      ? this.state.productData.inventoryCount
      : 0;
    var ordered = this.state.productData?.ordered
      ? this.state.productData.ordered
      : 0;

    return (
      <div>
        <DataTable
          columns={ columns }
          data={ this.state.data }
          pagination
          striped
          highlightOnHover
          paginationTotalRows={ this.state.total }
          paginationServer
          // onChangeRowsPerPage={this.handlePerRowsChange}
          // onChangePage={this.handlePageChange}
          // expandableRows
          // expandableRowsComponent={
          //   <ProductInv productID={this.state.mapData.productID}
          theme="solarized"
        />
      </div>
    );
  }
}

export default InvHist;
