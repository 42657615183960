import React, { Component } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "react-data-table-component";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStore,
  faUserClock,
  faQuestionCircle,
  faMinusSquare,
  faPenSquare,
} from "@fortawesome/free-solid-svg-icons";

import InvHist from "./InvHist";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { Link } from "react-router-dom";

const GoldMasterRestAPI = require("../service/GoldMasterRestAPI.js").default;

const { Parser } = require("json2csv");

//https://ellismin.com/2020/05/next-pagination/

class InventoryList extends Component {
  //var mapData;
  //var setMapData;
  state = {
    mapData: {},
    loading: false,
    totalRows: 0,
    perPage: 20,
    currentPage: 1,
    sortDirection: 1, // or -1 (1 is ascending, -1 is descending)
    sortSelector: null,
  };

  handleOnSort = (column, sortDirection) => {
    // https://github.com/jbetancur/react-data-table-component#sorting
    const { selector } = column;

    this.setState(
      {
        sortDirection: sortDirection === "asc" ? 1 : -1, // assume there are only asc and desc
        sortSelector: selector,
      },
      () => this.getInvData()
    );
  };

  handlePageChange = (page) => {
    this.setState(
      {
        currentPage: page,
      },
      () => this.getInvData(page)
    );
  };
  handlePerRowsChange = (perPage, page) => {
    this.setState(
      {
        perPage: perPage,
        currentPage: page,
      },
      () => {
        this.getInvData(page);
      }
    ); // we don't call setState to avoid double call
  };

  columns = [
    {
      name: "Product ID",
      selector: "productID",
      sortable: true,
      right: true,
    },
    {
      name: "Inventory ID",
      selector: "_id",
      sortable: true,
      right: true,
    },
    {
      name: "Labour Fee",
      selector: "labourFee",
      sortable: true,
      right: true,
    },

    { name: "Owner", selector: "owner", sortable: true, right: true },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      right: true,
      format: (row, index) => {
        let icon = faUserClock;
        let color = "";

        switch (row.status) {
          case "checkout":
            color = "";
            icon = faUserClock;
            break;
          case "ready":
            icon = faStore;
            color = "green";
            break;
          case "sold":
            icon = faQuestionCircle;
            color = "orange";
            break;
          case "return-to-vendor":
            icon = faStore;
            color = "yellow";
            break;

          default:
            icon = faQuestionCircle;
            color = "orange";
        }

        return (
          <div>
            <FontAwesomeIcon icon={icon} color={color} />
            {row.status}
          </div>
        );
      },
    },

    {
      name: "Weight",
      selector: "weight",
      sortable: true,
      right: true,
    },
    {
      name: "Create",
      selector: "createdAt",
      sortable: true,
      format: (row, index) => {
        return (
          (row.createdAt &&
            moment(row.createdAt).tz("Asia/Hong_Kong").format("lll")) ||
          "N/A"
        );
      },
    },
    {
      name: "Create By",
      selector: "createdBy",
      sortable: true,
      format: (row, index) => {
        return row.createdBy?.email || "N/A";
      },
    },
    {
      name: "Update",
      selector: "updateAt",
      sortable: true,
      format: (row, index) => {
        return (
          (row.updateAt &&
            moment(row.updateAt).tz("Asia/Hong_Kong").format("lll")) ||
          "N/A"
        );
      },
    },
    {
      name: "Update By",
      selector: "updateBy",
      sortable: true,
      format: (row, index) => {
        return row.updateBy?.email || "N/A";
      },
    },
    {
      name: "Action",
      selector: "status",
      format: (row, index) => {
        return <div></div>;
      },
    },
  ];
  /*
            <FontAwesomeIcon
              size="lg"
              icon={faMinusSquare}
              color="red"
              onClick={this.deleteInv.bind(this, row)}
            />
            <Link to={`/InventoryEdit/${row._id}`}>
              <FontAwesomeIcon
                size="lg"
                icon={faPenSquare}
                style={{ marginLeft: "5px" }}
                color="red"
              />
            </Link>

*/

  getCSV = () => {
    let idata;
    GoldMasterRestAPI.getData({
      page: 0,
      perPage: 100000,
      service: "inventory",
      sort: { updateAt: -1 },
    })
      .then((data) => {
        console.info("====== Inv ======");

        idata = data.data;

        console.log(data);
        return data;
      })
      .then((data) => {
        GoldMasterRestAPI.getData({
          page: 0,
          perPage: 100000,
          service: "product",
        }).then((data) => {
          let pdata = data.data;
          console.info("Product DATA:");
          console.info(pdata);

          let pdataHT = {};
          for (var i = 0; i < pdata.length; i++) {
            var obj = pdata[i];
            pdataHT[obj["_id"]] = obj;
          }
          console.info(pdataHT);

          for (var i = 0; i < idata.length; i++) {
            var obj = idata[i];

            idata[i]["url"] = "https://k-gold.shop/p/" + obj["_id"];
            idata[i]["Inventory Code"] = obj["_id"];

            idata[i]["weight_gram"] = obj["weight"];
            idata[i]["weight_tael"] = (obj["weight"] / 37.429).toFixed(3);
            if (obj["productID"]) {
              //console.log(pdataHT[obj["productID"]]);
              //  console.log(obj["productID"]);
              idata[i]["rent"] = pdataHT[obj["productID"]]?.rentalCost;
              if (!idata[i]["rent"]) idata[i]["rent"] = "N/A";
            } else {
              idata[i]["rent"] = "N/A";
            }
          }

          const fields = [
            "Inventory Code",
            "url",
            "productID",
            "weight_gram",
            "weight_tael",
            "rent",
            "labourFee",
            "owner",
            "updatedAt",
            "status",
          ];
          const opts = { fields: fields, header: true };

          console.info(idata);
          const json2csvParser = new Parser(opts);
          const csv = json2csvParser.parse(idata);

          console.info(csv);

          const csvContent = new Blob([csv], { type: "text/plain" });

          const csvLink = window.URL.createObjectURL(csvContent);
          this.setState({
            csv: csvLink,
          });
        });
      });
  };

  getInvDataPromise = (page) => {
    const { perPage } = this.state;
    let show_page = page ? page : 1;

    let skip = (show_page - 1) * perPage;

    this.setState({ loading: true });

    GoldMasterRestAPI.getData({
      page: page,
      perPage: perPage,
      service: "inventory",
    }).then((data) => {
      console.info("====== Inv ======");

      console.info(data);
      this.setState({
        mapData: data,
        totalRows: data.total,
        loading: false,
        //        perPage: perPage,
      });
    });

    //    console.info(data);
  };

  getInvData = (page = this.state.currentPage) => {
    const { perPage, sortSelector, sortDirection } = this.state;
    let show_page = page ? page : 1;

    let skip = (show_page - 1) * perPage;

    this.setState({ loading: true });

    GoldMasterRestAPI.getData({
      page: page,
      perPage: perPage,
      service: "inventory",
      // https://github.com/gaearon/feathers-docs/blob/master/databases/pagination.md#sort
      sort: sortSelector ? { [sortSelector]: sortDirection } : null,
    }).then((data) => {
      console.info("====== Inv ======");

      console.info(data);
      this.setState({
        mapData: data,
        totalRows: data.total,
        loading: false,
        //        perPage: perPage,
      });
    });

    //    console.info(data);
  };

  componentDidMount() {
    this.getInvData();
  }

  deleteInv = (row, event) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: `Are you sure to delete Inventory ${row._id}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.info(row._id);
            GoldMasterRestAPI.remove({
              id: row._id,
              service: "inventory",
            }).then((result) => {
              this.getInvData();
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    return (
      <>
        <h1>
          Inv of {this.state.mapData.total} Entries{" "}
          {/* {JSON.stringify(data) */}
        </h1>
        {
          //<button onClick={this.getInvData}>Update</button>
          <button onClick={this.getCSV}>Prepare CSV</button>
        }
        {this.state.csv ? (
          <div>
            <a download="inventory.csv" href={this.state.csv}>
              Download{" "}
            </a>
          </div>
        ) : (
          <div></div>
        )}
        <DataTable
          columns={this.columns}
          data={this.state.mapData.data}
          pagination
          striped
          highlightOnHover
          paginationTotalRows={this.state.mapData.total}
          paginationServer
          onChangeRowsPerPage={this.handlePerRowsChange}
          onChangePage={this.handlePageChange}
          paginationPerPage={this.state.perPage}
          paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
          expandableRows
          expandableRowsComponent={<InvHist />}
          sortServer
          onSort={this.handleOnSort}
        />
      </>
    );
  }
}

export default InventoryList;
