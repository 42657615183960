import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "react-data-table-component";
import moment from "moment-timezone";

const GoldMasterRestAPI = require("../service/GoldMasterRestAPI.js").default;

//https://ellismin.com/2020/05/next-pagination/

const columns = [
  {
    name: "Time",
    selector: "createdAt",
    sortable: true,
    format: (row, index) => {
      return moment(row.createdAt).tz("Asia/Hong_Kong").format("lll");
    },
  },
  {
    name: "周生生",
    selector: "pricemap.周生生",
    sortable: true,
    right: true,
  },
  {
    name: "周大福",
    selector: "pricemap.周大福",
    sortable: true,
    right: true,
  },
  {
    name: "六福",
    selector: "pricemap.六福",
    sortable: true,
    right: true,
  },
  {
    name: "謝瑞麟",
    selector: "pricemap.謝瑞麟",
    sortable: true,
    right: true,
  },
  {
    name: "粵港澳湛周生生",
    selector: "pricemap.粵港澳湛周生生",
    sortable: true,
    right: true,
  },
  {
    name: "金至尊",
    selector: "pricemap.金至尊",
    sortable: true,
    right: true,
  },
];

class PriceList extends Component {
  //var mapData;
  //var setMapData;
  state = {
    mapData: {},
    loading: false,
    totalRows: 0,
    perPage: 20,
  };

  handlePageChange = (page) => {
    this.getPriceData(page);
  };
  handlePerRowsChange = (perPage, page) => {
    this.setState({ perPage: perPage }); // we don't call setState to avoid double call
    this.getPriceData(page);
  };

  getPriceData = async (page) => {
    console.info(this.state);
    console.info(page);
    const { perPage } = this.state;
    let show_page = page ? page : 1;

    let skip = (show_page - 1) * perPage;
    console.info(skip);

    this.setState({ loading: true });

    //GoldMasterRestAPI.getData({page:1, perPage:10,service:"price"});
    /*
		GoldMasterRestAPI.getData({ page: 1, perPage: 10, service: "price" })
			.then(() => {})
			.catch((e) => {});*/
    const data = await GoldMasterRestAPI.getData({
      page: page,
      perPage: perPage,
      service: "price",
    });

    data.data.map((item) => {
      item.pricemap = {};
      return item.price.map((price) => {
        item.pricemap[price.name] = price.price;
        return (
          <tr>
            <td>{price.name}</td>
            <td>{price.price}</td>
          </tr>
        );
      });
    });

    this.setState({
      mapData: data,
      totalRows: data.total,
      loading: false,
      perPage: perPage,
    });

    //    console.info(data);
  };

  componentDidMount() {
    this.getPriceData();
  }
  render() {
    return (
      <>
        <h1>
          Price History of {this.state.mapData.total} Entries{" "}
          {/* {JSON.stringify(data) */}
        </h1>
        {
          //<button onClick={this.getPriceData}>Update</button>
        }
        <DataTable
          columns={columns}
          data={this.state.mapData.data}
          pagination
          striped
          highlightOnHover
          paginationTotalRows={this.state.mapData.total}
          paginationServer
          onChangeRowsPerPage={this.handlePerRowsChange}
          onChangePage={this.handlePageChange}
          paginationPerPage={this.state.perPage}
        />
      </>
    );
  }
}

export default PriceList;
