import React, { Component } from "react";
import { Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { Button, Container, Form } from "react-bootstrap";
import { faCircle, faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons";

import { RentalNoteInputRow } from "./RentalNoteInputRow";
import { faPencilRuler } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStore,
  faUserClock,
  faQuestionCircle,
  faSync,
  faCheckCircle,
  faExclamationTriangle,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import DatePicker from "react-datepicker";

import "../../../node_modules/react-datepicker/dist/react-datepicker.css";

import jquery from "jquery";
import $ from "jquery";
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";

import NumberFormat from "react-number-format";
import { faGoogleDrive } from "@fortawesome/free-brands-svg-icons";
const GoldMasterRestAPI = require("../../service/GoldMasterRestAPI.js").default;
const cryptoRandomString = require("crypto-random-string");
var QRCode = require("qrcode.react");

//https://fontawesome.com/icons/sync?style=solid
//https://react-bootstrap.github.io/components/input-group/

///price_/getLatestPrice
export class RentalNoteInput extends Component {
  constructor() {
    setDefaultLocale("en");
    super();
    let i = 0;

    //create enough cells for input
    for (i = 0; i < 3; i++) {
      let ref = React.createRef();
      this.cells.push({ key: i, icon: faCircle, ref: ref });
    }
    //this.cells = [{ key: 0, icon: faCircle }];

    this.field_client = React.createRef();
    this.field_mobile = React.createRef();
    this.field_details = React.createRef();
    this.override_total = React.createRef();

    this.field_clientno = React.createRef();
    this.field_mobile = React.createRef();
    this.field_details = React.createRef();
    this.field_rentfrom = React.createRef();
    this.field_rentto = React.createRef();
    this.field_staffno = React.createRef();
    this.field_iddoc = React.createRef();
    this.field_staffno = React.createRef();
    this.field_paymenttype = React.createRef();
    this.field_invoiceno = React.createRef();
    this.field_total = React.createRef();
    this.field_discount_flat = React.createRef();

    this.skipPayment = "";

    this.state = {
      cells: this.cells,
      total: 0,
      status: "draft",
      goldPrice: 0,
      goldPricePerTael: 0,
      discount: 0,
      deposit_discount_percent: 0,
      discount_percent: 0,
      discount_flat: 0,
      totalWeight: 0,
      totalWeightTael: 0,
    };
  }

  ensureEmptyRows = () => {
    let ref = React.createRef();
    let filled = this.cells.filter((elm) => {
      //      console.log(elm);
      return elm.ref.current?.inventoryID_val;
    }).length;

    if (this.cells.length - filled < 1) {
      let i = this.cells.length + 1;
      this.cells.push({ key: i, icon: faCircle, ref: ref });
    }
  };

  componentDidMount() {
    //get the latest price
    this.getPriceFromApi();
    this.updateInteval = setInterval(() => {
      this.getPriceFromApi();
    }, 60000 * 10);
  }
  componentWillUnmount() {
    clearInterval(this.updateInteval);
  }

  getPriceFromApi() {
    console.log(GoldMasterRestAPI.getGoldPrice());
    GoldMasterRestAPI.getGoldPrice()
      .then((result) => {
        this.setState({
          goldPrice: Number.parseFloat((result.minPrice - 50) / 37.429).toFixed(
            3
          ),
        });
        this.setState({ goldPricePerTael: result.minPrice - 50 });

        //        console.info(result);
      })
      .catch((error) => {
        console.info(error);
      });
  }

  submitToAPIwithoutPayment = () => {
    let payload = {};
    payload.skipPayment = "SkipPayment";
    this.skipPayment = "SkipPayment";

    this.submitToAPI(payload);
  };

  submitToAPI = (payload) => {
    this.setState({ workingState: "running" });

    let rows = this.cells
      .map((cell) => {
        let c = cell.ref.current;

        return c.getData();
      })
      .filter((v) => {
        return v.invId;
      });

    // there are error when directly loading payload into order
    // let order = { ...payload };
    let {
      dispatchConfig,
      _dispatchListeners,
      nativeEvent,
      type,
      eventPhase,
      bubbles,
      cancelable,
      timeStamp,
      defaultPrevented,
      isTrusted,
      detail,
      screenX,
      screenY,
      clientX,
      clientY,
      pageX,
      pageY,
      ctrlKey,
      shiftKey,
      altKey,
      metaKey,
      getModifierState,
      button,
      buttons,
      relatedTarget,
      movementX,
      movementY,
      isDefaultPrevented,
      isPropagationStopped,
    } = payload;
    let order = {
      dispatchConfig,
      _dispatchListeners,
      nativeEvent,
      type,
      eventPhase,
      bubbles,
      cancelable,
      timeStamp,
      defaultPrevented,
      isTrusted,
      detail,
      screenX,
      screenY,
      clientX,
      clientY,
      pageX,
      pageY,
      ctrlKey,
      shiftKey,
      altKey,
      metaKey,
      getModifierState,
      button,
      buttons,
      relatedTarget,
      movementX,
      movementY,
      isDefaultPrevented,
      isPropagationStopped,
    };

    if (this.skipPayment && this.skipPayment != "") {
      order.skipPayment = "skipPayment";
    }

    var totalWeight = 0.0;
    rows.forEach(function (row, index) {
      row.index = index + 1;
      totalWeight += parseFloat(row.weight);
    });

    var totalWeightTael = 0.0;
    rows.forEach(function (row, index) {
      row.index = index + 1;
      totalWeightTael += parseFloat(row.weightTael);
    });

    order.row = rows;
    /*
    order.total = rows
      .map((v) => v.subtotal)
      .reduce((acc, v) => parseFloat(acc) + parseFloat(v), 0);
*/

    order.field_client = this.field_client.current.value;
    order.field_clientno = this.field_clientno.current.value;
    order.field_mobile = this.field_mobile.current.value;
    order.field_details = this.field_details.current.value;
    order.field_goldprice = this.state.goldPricePerTael;

    order.field_rentfrom = this.state.field_rentfrom;
    order.field_rentto = this.state.field_rentto;
    order.field_staffno = this.field_staffno.current.value;
    order.field_iddoc = this.field_iddoc.current.value;
    order.field_paymenttype = this.field_paymenttype.current.value;
    order.field_invoiceno = this.field_invoiceno.current.value;
    order.field_discount_percentage =
      "" + parseFloat(this.state.discount).toFixed(2) + "%";
    order.field_discount = this.field_discount_flat.current.value;

    // TODO overrided by Manual Input
    order.total = this.field_total.current.value;

    order.date = moment(new Date()).format("DD-MM-YYYY");

    console.log("totalWeight:");
    console.log(totalWeight);
    order.totalWeight = parseFloat(totalWeight).toFixed(3);
    order.field_totaltael = parseFloat(totalWeightTael).toFixed(3);

    var today = new Date();

    let id =
      today.getFullYear() +
      "" +
      (today.getMonth() + 1) +
      "" +
      today.getDate() +
      "-" +
      order.field_mobile.substring(0, 4) +
      "-" +
      cryptoRandomString({ length: 5, type: "distinguishable" });

    console.log("Order consclude : " + id);
    console.log(order);

    GoldMasterRestAPI.create({
      service: "orders",
      data: {
        order: order,
        status: "order-ready",
        _id: id,
        id: id,
      },
    })
      .then((data) => {
        this.setStatus("order-ready");
        console.info(data);

        if (payload.skipPayment && payload.skipPayment === "SkipPayment") {
          Axios.get(GoldMasterRestAPI.apiURL + "/paySkipPayment/" + id)
            .then((data) => {
              console.info(GoldMasterRestAPI.apiURL + "/payOrder/" + id);
              console.info(data);
              this.setState({
                status: "done_payment",
                workingState: "done",
              });
              this.setStatus("done");
            })
            .catch((err) => {
              console.info(
                "error " + GoldMasterRestAPI.apiURL + "/paySkipPayment/" + id
              );
              console.info(err);
              this.setState({
                workingState: "done",
              });
            });
        } else {
          Axios.get(GoldMasterRestAPI.apiURL + "/payOrder/" + id)
            .then((data) => {
              console.info(GoldMasterRestAPI.apiURL + "/payOrder/" + id);
              console.info(data);
              this.setState({
                //paymentLink: data.data.link.approve,
                paymentLink: GoldMasterRestAPI.apiURL + "/payDeposit/" + id,
                status: "ready_payment",
                workingState: "done",
              });
            })
            .catch((err) => {
              console.info(
                "error " + GoldMasterRestAPI.apiURL + "/payOrder/" + id
              );
              console.info(err);
              this.setState({
                workingState: "done",
              });
            });
        }
      })
      .catch((e) => {
        //target.setLastError(e);
        console.log(e);
        this.setStatus("error");
      });
  };

  submitInput = (targetDom) => {};

  childUpdate = (targetChild) => {
    var alldata = this.getSubData();

    //console.log(alldata);
    var totalWeight = alldata
      .map((cell) => {
        return parseFloat(cell.weight) ? parseFloat(cell.weight) : 0.0;
      })
      .reduce((total, c) => total + c, 0.0);

    var totalWeightTael = alldata
      .map((cell) => {
        return parseFloat(cell.weightTael) ? parseFloat(cell.weightTael) : 0.0;
      })
      .reduce((total, c) => total + c, 0.0);

    if (!totalWeight) totalWeight = 0.0;

    this.setState(
      {
        totalWeight: totalWeight,
        totalWeightTael: totalWeightTael,
      },
      () => {
        this.updateTotal();
        this.ensureEmptyRows();
      }
    );
  };

  updateTotal = () => {
    let total = this.cells
      .map((cell) => cell.ref.current.getSubtotal())
      .reduce((total, c) => total + c, 0);

    this.setState({ total: total });
  };

  isDuplicated = (id) => {
    let exists = this.cells
      .map((cell) => cell.ref.current.getData().invId)
      .filter((c) => c === id);

    console.log(exists.length > 0);
    return exists.length > 0;
  };

  getSubData = () => {
    let allCells = this.cells

      .map((cell) => cell.ref.current.getData())
      .filter((cell) => {
        //        console.log(cell);
        return cell.invId;
      });

    this.setState({ allData: allCells });

    //    console.log("========");

    return allCells;
  };

  cellIdCount = 10;
  cells = [];

  updateState = () => {
    this.setState({ cells: this.cells });
  };

  updateTotalFromInput = (e) => {
    console.info("-------");
    console.info(this.override_total.current.value);
    console.info(e);
    this.setState({ total: this.override_total.current.value }); // updateTotalFromInput
  };

  formatMoney_one = (amount) => {
    return Number.parseFloat(amount).toFixed(1);
  };

  formatMoney = (amount) => {
    return Number.parseFloat(amount).toFixed(2);
  };

  formatWeight = (amount) => {
    return Number.parseFloat(amount).toFixed(3);
  };

  setStatus = (status) => {
    let icon = faCircle;
    let className = "";
    let color = "";
    let error = this.lastError ? this.lastError : {};
    switch (status) {
      case "init":
        icon = faCircle;

        break;
      case "ing":
        icon = faSync;
        className = "fa-spin";
        break;
      case "done":
        icon = faCheckCircle;
        color = "green";
        break;

      case "done_payment":
        icon = faCheckCircle;
        color = "green";
        break;

      case "error":
        icon = faExclamationTriangle;
        color = "red";
        break;
      default:
        icon = faCircle;
        break;
    }
    this.setState({
      icon: icon,
      className: className,
      color: color,
      lastError: error,
    });
    console.info(error);
  };

  dateSelect = (e, field_name) => {
    console.log(e);
    console.log(field_name);
    var state = {};
    //var edate = moment(e).format("yyyy/MM/DD");
    state[field_name] = e;
    this.setState(state);
  };
  dateChange = (e, field_name) => {
    console.log(e);
    console.log(field_name);
    var state = {};
    //    var edate = moment(e).format("yyyy/MM/DD");
    state[field_name] = e;
    this.setState(state);
  };

  handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      this.setState({ valid: true });
      console.log("no goood");
    } else {
      this.setState({ valid: true });
      console.log("good");

      this.submitToAPI({});
    }
  };

  render() {
    let cellsTable =
      /*
        (
      <InventoryInputRow icon={faSync} submit={this.submitInput} />
        );
        */
      this.state.cells.map((cell) => {
        return (
          <RentalNoteInputRow
            icon={cell.icon}
            key={cell.key}
            mykey={cell.key}
            submit={this.submitInput}
            goldPrice={this.state.goldPrice}
            updated={this.childUpdate}
            ref={cell.ref}
            checkIsDuplcated={this.isDuplicated}
          />
        );
      });
    cellsTable.unshift(<RentalNoteInputRow inputMode="true" />);

    const renderStatus = (status) => {
      switch (status) {
        case "draft":
          return (
            <span>
              <FontAwesomeIcon icon={faPencilRuler} color="orange" />
              Draft
            </span>
          );
        case "ready_payment":
          return (
            <span>
              <FontAwesomeIcon icon={faMoneyBillAlt} color="green" />
              Ready to Accept Payment
            </span>
          );
        case "done":
          return (
            <span>
              <FontAwesomeIcon icon={faCheckCircle} color="green" />
              Done
            </span>
          );

        //paymentLink: data.link.authorize,
        //status: "ready_payment",
        default:
          return (
            <span>
              <FontAwesomeIcon icon={faExclamationTriangle} color="red" />
              {status}
            </span>
          );
      }
    };

    // Prod: https://drive.google.com/drive/folders/1hm0grZL1BDLj-m9q4sat_rwHAaTMC9aF
    // UAT: https://drive.google.com/drive/folders/1modRZoFL_m205SretDzvcHdDNuBbqBDY?usp=sharing
    return (
      <Form
        noValidate
        validated={this.state.valid}
        onSubmit={this.handleSubmit}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <h1>
                Create Rental Note &nbsp;
                <a href="https://drive.google.com/drive/folders/1hm0grZL1BDLj-m9q4sat_rwHAaTMC9aF">
                  <FontAwesomeIcon icon={faGoogleDrive} />
                </a>
              </h1>
              <h4>
                Current Gold Price: {this.state.goldPrice} (per Gram) (
                {this.state.goldPricePerTael}) (Per Tael)
              </h4>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Container>{cellsTable}</Container>
            </Col>
            {/* <!--  Lower Form - Left --> */}
            <Col xs={6}>
              <ClientField
                varName="clientNo"
                text="Client No."
                refID={this.field_clientno}
                required
              />

              <ClientField
                varName="clientName"
                text="Client Name"
                refID={this.field_client}
                required
              />
              <ClientField
                varName="clientPhone"
                text="Mobile"
                refID={this.field_mobile}
                required
              />
              <ClientField
                varName="clientDetail"
                text="Details"
                fieldtype="textarea"
                rows={10}
                refID={this.field_details}
              />

              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="rentFrom">Rent From</InputGroup.Text>
                </InputGroup.Prepend>

                <DatePicker
                  ref={this.field_rentfrom}
                  value={this.state.field_rentfrom}
                  selected={this.state.field_rentfrom}
                  onSelect={(e) => this.dateSelect(e, "field_rentfrom")} //when day is clicked
                  onChange={(e) => {
                    this.dateChange(e, "field_rentfrom");
                    // add 2 date only if "Rent To" is empty
                    if (!this.state.field_rentto) {
                      this.dateChange(
                        moment(e).add(2, "days").toDate(),
                        "field_rentto"
                      );
                    }
                  }} //only when value has changed
                  locale="en"
                  dateFormat="dd/MM/yyyy"
                  required
                />
              </InputGroup>

              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="rentTo">Rent To</InputGroup.Text>
                </InputGroup.Prepend>

                <DatePicker
                  selected={this.state.field_rentto}
                  ref={this.field_rentto}
                  onSelect={(e) => this.dateSelect(e, "field_rentto")} //when day is clicked
                  onChange={(e) => this.dateChange(e, "field_rentto")} //only when value has changed
                  value={this.state.field_rentto}
                  locale="en"
                  dateFormat="dd/MM/yyyy"
                  required
                />
              </InputGroup>

              <br />
            </Col>
            {/* <!--  Lower Form - right --> */}
            <Col xs={6}>
              <ClientField
                varName="invoiceno"
                text="Invoice No."
                refID={this.field_invoiceno}
                required
              />
              <ClientField
                varName="iddoc"
                text="Identification Documents"
                refID={this.field_iddoc}
                required
              />

              <ClientField
                varName="staffno"
                text="Staff No."
                refID={this.field_staffno}
              />
              <ClientField
                varName="paymenttype"
                text="Payment Type"
                refID={this.field_paymenttype}
                required
              />

              <br />

              <Container>
                <Row>
                  <Col xs={12}>
                    <ClientField
                      varName="goldprice"
                      text="Gold Price"
                      refID={this.field_goldprice}
                      value={this.state.goldPricePerTael}
                      readonly
                    />
                  </Col>
                  <Col xs={6}>
                    <ClientField
                      varName="Total Weight"
                      text="Total Weight"
                      refID={this.field_totalweight}
                      value={this.formatWeight(this.state.totalWeight)}
                      readonly
                    />
                  </Col>
                  <Col xs={6}>
                    <ClientField
                      varName="Total Weight"
                      text="Total Weight (Tael)"
                      refID={this.field_totalweight_tael}
                      value={this.formatWeight(this.state.totalWeightTael)}
                      readonly
                    />
                  </Col>
                  <Col xs={12}>
                    <ClientField
                      varName="Sub Total"
                      text="Subtotal"
                      value={this.formatMoney_one(
                        this.formatWeight(this.state.totalWeightTael) *
                          this.state.goldPricePerTael
                      )}
                      readonly
                      money={true}
                    />
                  </Col>
                  <Col xs={12}>
                    <ClientField
                      varName="discount"
                      text="Discount %"
                      refID={this.field_discount}
                      value={this.state.discount}
                      change={(e) => {
                        this.setState({ discount: e.target.value });
                      }}
                    />
                  </Col>
                  <Col xs={12}>
                    <ClientField
                      varName="discount_flat"
                      text="Discount Flat"
                      refID={this.field_discount_flat}
                      value={this.state.discount_flat}
                      change={(e) => {
                        this.setState({ discount_flat: e.target.value });
                      }}
                    />
                  </Col>
                  <Col xs={12}>
                    <ClientField
                      varName="Total"
                      text="Total"
                      refID={this.field_total}
                      value={
                        this.override_total?.current?.value &&
                        this.override_total.current.value != ""
                          ? this.override_total.current.value
                          : this.formatMoney_one(
                              (this.formatWeight(this.state.totalWeightTael) *
                                this.state.goldPricePerTael *
                                (100 - this.state.discount)) /
                                100 -
                                this.state.discount_flat
                            )
                      }
                      readonly
                      money={true}
                    />
                  </Col>
                </Row>
              </Container>

              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="Manual Total">
                    Override Total
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <FormControl
                  placeholder=""
                  aria-label=""
                  aria-describedby=""
                  //value={this.state.total}

                  ref={this.override_total}
                  onChange={this.updateTotalFromInput}
                />
                <NumberFormat
                  value={this.override_total.current?.value}
                  thousandSeparator={true}
                  prefix={"$"}
                  displayType={"text"}
                  style={{ float: "right", padding: "10px" }}
                  decimalScale={2}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>Status: {renderStatus(this.state.status)}</Col>
            <Col xs={4}></Col>
            <Col xs={4}>
              <div></div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {this.state.workingState &&
              this.state.workingState === "running" ? (
                <FontAwesomeIcon icon={faSync} className="fa-spin" />
              ) : null}
              <button className="btn btn-primary" type="submit">
                Submit
              </button>
              <button className="btn btn-secondary" type="button">
                Cancel
              </button>
              <br />
              <br />
              <button
                className="btn btn-info"
                type="button"
                onClick={this.submitToAPIwithoutPayment}
              >
                Skip Payment Submit
              </button>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              {this.state.paymentLink ? (
                <Row>
                  <Col xs={12}>
                    <input value={this.state.paymentLink} readOnly />
                    <br />
                    <a href={this.state.paymentLink} target="_blank">
                      <QRCode value={this.state.paymentLink} />
                      <br />
                      Deposit Now
                    </a>
                  </Col>
                </Row>
              ) : null}{" "}
            </Col>
            <Col xs={4}></Col>
            <Col xs={4}></Col>
          </Row>
        </Container>
      </Form>
    );
  }
}

export default RentalNoteInput;

class ClientField extends React.Component {
  props = {};
  constructor(props) {
    super(props);

    /*
    let { varName, text, refName, fieldtype, rows, onChange } = props;

    this.varName = varName;
    this.text = text;
    this.refName = refName;
    this.fieldtype = fieldtype;
    this.rows = rows;
    this.onChange = onChange;
    */

    this.props = props;
    this.state = {};
  }

  render() {
    return (
      <div>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id={this.props.varName}>
              {this.props.text}
            </InputGroup.Text>
          </InputGroup.Prepend>

          {
            <FormControl
              placeholder=""
              aria-label={this.props.varName}
              aria-describedby={this.props.varName}
              ref={this.props.refID}
              as={this.props.fieldtype}
              rows={this.props.rows}
              className={this.props.readonly ? "classReadOnly" : ""}
              onChange={
                this.props.readonly
                  ? () => {}
                  : this.props.change
                  ? this.props.change
                  : null
              }
              value={this.props.value}
              required={this.props.required}
            />
          }
          {this.props.money ? (
            <NumberFormat
              value={this.props.value}
              thousandSeparator={true}
              prefix={"$"}
              displayType={"text"}
              style={{ float: "right", padding: "10px" }}
              decimalScale={2}
            />
          ) : null}
        </InputGroup>
      </div>
    );
  }
}

/*
function ClientField() {
  return;
}*/
