import React, { Component } from "react";
import { Row, Col, InputGroup, FormControl } from "react-bootstrap";
import {
  faCircle,
  faSync,
  faCheckCircle,
  faExclamationTriangle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Alert from "react-bootstrap/Alert";
import Autosuggest from "react-autosuggest";
import isMobile from "ismobilejs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import NumberFormat from "react-number-format";

const GoldMasterRestAPI = require("../../service/GoldMasterRestAPI.js").default;

//https://react-select.com/async

const getOptions = (input) => {
  let data = [];

  return data;
};

const focusInputOnSuggestionClick = !isMobile.any;

const renderSuggestion = (suggestion) => <span>{suggestion.label}</span>;
export class OrderNoteItem extends Component {
  state = { icon: "", suggestions: [] };

  constructor({
    props,
    icon,
    submit,
    mykey,
    goldPrice,
    updated,
    inputMode,
    defaultValues,
  }) {
    super(props);
    this.state.icon = icon;
    this.state.value = defaultValues?.productIdValue || "";
    this.state.suggestions = [];
    this.state.unitPriceValue = defaultValues?.unitPriceValue || 0.0;
    this.state.metal = defaultValues?.metal || "AU999.9";
    this.state.weight = defaultValues?.weight || "";
    this.state.subTotalValue = defaultValues?.subTotal || "0.0";
    this.state.discount =
      (defaultValues?.discount && Number(defaultValues?.discount)) || 0.0;
    this.state.show = true;
    this.state.weightTael =
      (defaultValues?.weight &&
        this.formatWeight(defaultValues?.weight / 37.429)) ||
      0.0;
    this.state.desc = defaultValues?.desc || "";

    this.discountValue = 0.0;
    this.unitPriceValue = 0.0;

    this.className = "";
    this.submitCall = submit;
    this.key = mykey;

    this.updateHook = updated;

    this.state.goldprice = goldPrice;
    this.inventoryID = React.createRef();
    this.unitPrice = React.createRef();
    this.desc = React.createRef();
    this.metal = React.createRef();
    this.productID = React.createRef();
    this.discount = React.createRef();
    this.weight = React.createRef();
    this.subTotal = React.createRef();

    this.inputMode = inputMode;
    //this.setState({ icon: icon });
  }

  getSuggestionValue = (suggestion) => {
    //return suggestion.value;

    console.log("Suggestion");
    console.log(suggestion);
    this.setState(
      {
        selected: suggestion,
        //      unitPriceValue: suggestion.unitPrice,
        inventoryID_val: suggestion._id,
        productID: suggestion.productIdValue,
        unitPriceValue: suggestion.rentalCost,
        desc: suggestion.ProductName,
        weight: suggestion.weight,
        weightTael: this.formatWeight(suggestion.weight / 37.429),
        subtotalValue: this.updateSubtotalValue(),
        metal: suggestion.Metal,
      },
      () => {
        this.updateSubtotalValue();
        this.updateHook(this);
      }
    );

    /*
    GoldMasterRestAPI.getData({
      service: "product",
      perPage: 100,
      query_parameter: { _id: suggestion.productIdValue },
    }).then((result) => {
      //console.log(apiResult);

      this.setState(
        {
          metal: result?.data[0]?.Metal,
        },
        () => {}
      );
    });
    */

    //   this.unitPriceValue = suggestion.unitPrice;
    //   this.productID = suggestion.productID;
    //   this.inventoryID_val = suggestion._id;
    //   this.metal_val = suggestion.metal;

    //   this.updateHook(this);

    return suggestion.value;
  };
  onIDChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onChange = (e) => {
    console.log("onChange");
    console.log(e);
    console.log(e.target);
    console.log(e.target.value);

    console.log("Sub Total Calculation");

    this.setState({
      subTotalValue: this.formatMoney(
        this.unitPrice.current.value *
          ((100 - this.discount.current.value) / 100)
      ),
    });
    this.updateHook();
    return e;
  };

  onSuggestionsFetchRequested = ({ value }) => {
    GoldMasterRestAPI.getData({
      service: "product",
      perPage: 100,
      query_parameter: { _id: { $search: value } },
    }).then((apiResult) => {
      console.log("------ product api call");
      console.log(apiResult);
      let result = [];
      apiResult.data.map((res) => {
        result.push({ ...res, label: res._id, value: res._id });
      });
      // { label: "1", value: "1", unitPrice: 1.0 },
      // { label: "1cccccc", value: "1112", unitPrice: 2.0 },
      // { label: "bbbbbbbb1", value: "bbb", unitPrice: 3.0 },
      this.setState({
        suggestions: result,
      });
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  clearSelection = () => {
    this.discountValue = 0;
    this.setState(
      {
        selected: null,
        value: "",
        unitPriceValue: "",
        weight: "",
        weightTael: "0",
        discount: 0,
        discountValue: 0,
        metal: "AU999.9",
        desc: "",
        subTotalValue: "0",
      },
      () => {
        this.value = "";
        this.updateHook(this);
      }
    );
  };

  getKey = () => {
    return this.key;
  };

  getData = () => {
    //console.log("get result froim child");

    //state value are not updated yet
    let result = {};
    result.productIdValue = this.productID.current.input.value;
    result.desc = this.desc.current.value;
    result.metal = this.state.metal;
    result.weight = this.state.weight;
    result.unitPriceValue = this.state.unitPriceValue;
    result.discount = this.discount.current.value;
    this.updateSubtotalValue();
    result.subTotal = this.subTotalValue;
    result.weightTael = this.state.weightTael;

    return result;
  };

  setIcon = (icon) => {
    this.setState({ icon: icon });
  };

  setLastError = (error) => {
    this.lastError = error;
  };
  setStatus = (status) => {
    let icon = faCircle;
    let className = "";
    let color = "";
    let error = this.lastError ? this.lastError : {};
    switch (status) {
      case "init":
        icon = faCircle;
        break;
      case "ing":
        icon = faSync;
        className = "fa-spin";
        break;
      case "done":
        icon = faCheckCircle;
        color = "green";
        break;

      case "error":
        icon = faExclamationTriangle;
        color = "red";
        break;
      default:
        icon = faCircle;
        break;
    }
    this.setState({
      icon: icon,
      className: className,
      color: color,
      lastError: error,
    });
    console.info(error);
  };

  submit = () => {
    this.submitCall(this);
  };

  updateSubtotalValue = () => {
    this.subTotalValue = this.formatMoney(
      this.state.unitPriceValue * ((100 - this.discountValue) / 100)
    );

    this.setState({ subTotalValue: this.subTotalValue });
    return this.subTotalValue;
  };

  overrideunitPrice = (event) => {
    this.setState(
      {
        unitPriceValue: event.target.value,
        subTotalValue: this.updateSubtotalValue(),
      },
      () => {
        this.updateSubtotalValue();
        this.updateHook();
      }
    );
    this.unitPriceValue = event.target.value;
  };

  changeDiscount = (event) => {
    this.setState(
      {
        discount: event.target.value,
        subTotalValue: this.updateSubtotalValue(),
      },
      () => {
        this.updateSubtotalValue();
        this.updateHook();
      }
    );
    this.discountValue = event.target.value;
  };

  formatMoney = (amount) => {
    return Number.parseFloat(amount).toFixed(2);
  };

  formatWeight = (amount) => {
    return Number.parseFloat(amount).toFixed(3);
  };

  hide = () => {
    this.productID.current.value = null;
    this.setState({ show: false });
  };

  render = () => {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "",
      value,
      onChange: this.onIDChange,
      className: "form-control",
    };

    return (
      <Row
        onChange={this.onChange}
        style={{ display: this.state.show ? null : "none" }}
      >
        <Col xs={3}>
          {this.inputMode ? (
            <div>Product ID</div>
          ) : (
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="Product">PID</InputGroup.Text>
              </InputGroup.Prepend>

              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                focusInputOnSuggestionClick={focusInputOnSuggestionClick}
                id="basic-example"
                ref={this.productID}
              />
              {this.state.selected && (
                <div>
                  <FontAwesomeIcon
                    icon={faTimes}
                    color="red"
                    onClick={this.clearSelection}
                  />
                </div>
              )}
            </InputGroup>
          )}
        </Col>
        <Col xs={2}>
          {this.inputMode ? (
            <div>Description</div>
          ) : (
            <InputGroup>
              <FormControl
                placeholder="Description"
                aria-label="Desc"
                aria-describedby="Description"
                value={this.state.desc}
                ref={this.desc}
                as="textarea"
                rows={2}
              />
            </InputGroup>
          )}
        </Col>

        <Col xs={2}>
          {this.inputMode ? (
            <div>
              <div>Metal</div>
              <div>Weight</div>
            </div>
          ) : (
            <div>
              <InputGroup>
                <FormControl
                  placeholder="Metal"
                  aria-label="Metal"
                  aria-describedby="Metal"
                  value={this.state.metal}
                  ref={this.metal}
                  onChange={(e) => {
                    this.setState({ metal: e.target.value });
                  }}
                />
              </InputGroup>

              <InputGroup>
                <FormControl
                  placeholder="G"
                  aria-label="weight"
                  aria-describedby="weight"
                  value={this.state.weight}
                  onChange={(e) =>
                    this.setState({
                      weight: e.target.value,
                      weightTael: this.formatWeight(e.target.value / 37.429),
                    })
                  }
                  ref={this.weight}
                />

                <span>({this.state.weightTael}兩)</span>
              </InputGroup>
            </div>
          )}
        </Col>

        <Col xs={2}>
          {this.inputMode ? (
            <div>
              <div>UnitPrice</div>
              <div>Discount</div>
            </div>
          ) : (
            <div>
              <InputGroup>
                <FormControl
                  placeholder="UnitPrice"
                  aria-label="UnitPrice"
                  aria-describedby="UnitPrice"
                  value={this.state.unitPriceValue}
                  ref={this.unitPrice}
                  onChange={this.overrideunitPrice}
                />
              </InputGroup>
              <InputGroup>
                <FormControl
                  placeholder="Discount"
                  aria-label="Discount"
                  aria-describedby="Discount"
                  value={this.state.discount}
                  ref={this.discount}
                  onChange={this.changeDiscount}
                />
                <InputGroup.Prepend>
                  <InputGroup.Text>% Disc</InputGroup.Text>
                </InputGroup.Prepend>
              </InputGroup>
            </div>
          )}
        </Col>

        <Col xs={2}>
          {this.inputMode ? (
            <div>Subtotal</div>
          ) : (
            <InputGroup className="text-right">
              <NumberFormat
                value={this.state.subTotalValue}
                thousandSeparator={true}
                prefix={"$"}
                displayType={"text"}
                style={{ float: "right", width: "100%" }}
                decimalScale={2}
              />

              <FormControl
                placeholder="Subtotal"
                aria-label="SubTotal"
                aria-describedby="SubTotal"
                value={this.state.subTotalValue}
                ref={this.subTotal}
                style={{ display: "none" }}
              />
            </InputGroup>
          )}

          {/* <FontAwesomeIcon icon={faTimes} onClick={this.hide} color="red" /> */}
        </Col>

        {this.state.lastError &&
          this.state.lastError !== {} &&
          this.state.lastError.message && (
            <Col xs={12}>
              <Alert variant="danger">{this.state.lastError.message}</Alert>
            </Col>
          )}
      </Row>
    );
  };
}
